.ms-header {
  background-color: #fff !important;
  width: 1200px;
  border: none !important;
}

.default-container .IDNMAxJzO_5lztnip .container .ms-footer {
  background-color: #f8f8f8 !important;
}

.ms-header .ms-header__account-info .ms-header__signin-button {
  background-color: #fff !important;
}

.ms-header__container .ms-header__logo {
  margin-left: 38px !important;
}

header {
  background-color: #fff !important;
}

.header_nav .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button,
.header_nav .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__link,
.header_nav .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__span {
  font-size: 12px !important;
  text-transform: uppercase !important;
  background: transparent !important;
}

.header_nav ul li {
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-left: 10px !important;
  padding-right: 15px;
  background: transparent !important;
  display: inline-block !important;
}

.header_nav ul li button {
  padding: 0px 15px !important;
}

.header_nav ul li div ul li {
  padding: 0px !important;
  margin: 0px !important;
  text-align: left !important;
  font-size: 11px !important;
  text-transform: uppercase !important;
}

.header_nav ul li div ul,
.header_nav ul li div ul li {
  display: block !important;
}

.head_pimk .ms-header__container {
  padding: 0px !important;
}

.ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button::after {
  font-size: 12px;
}

.ms-header__desktop-view {
  background-color: #fff !important;
  border: none !important;
}

.ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button::before,
.ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__link::before,
.ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__span::before {
  display: none !important;
}

.header_nav .ms-nav>.ms-nav__list,
.header_nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button,
.header_nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__link,
.header_nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__span {
  background-color: #fff !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
}

.ms-nav__list__item__button::before {
  display: none !important;
  position: relative !important;
}

.ms-nav__list__item__button::after {
  display: none !important;
}

.header_nav .ms-nav__list {
  background-color: #fff !important;
  display: inline-block !important;
}

.ms-nav,
.ms-nav .desktop-vp {
  border: none !important;
  background-color: #fff !important;
  text-align: center !important;
}

.content_tile1 .col-12 {
  max-width: 50% !important;
}

main {
  background-color: #fff !important;
}

.content_tile1 {
  max-width: 1170px !important;
}

.content_tile1 img {
  width: 100% !important;
}

.content_tile1 .msc-cta__primary {
  margin: auto;
  text-align: center;
  text-transform: uppercase !important;
  text-decoration: none !important;
  position: absolute;
  bottom: 60px;
  box-shadow: 4px 4px 0px 0px #0082ca;
  color: #333 !important;
  background-color: #fff !important;
  border: 0;
  width: auto;
  padding: 10px 2%;
  font-size: 1em !important;
  font-weight: 700 !important;
  min-width: 205px;
  border-radius: 3px !important;
  left: 180px;
}

.content_tile1 .msc-cta__primary:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.content_tile1 .msc-cta__primary div {
  width: 175px;
  text-align: center;
}

.content_tile1 .ms-content-block__cta {
  text-align: center;
}

.ms-content-block[data-m-layout="tile"] {
  width: 98% !important;
}

.promo_1 img {
  width: 1170px;
  height: auto;
  margin: auto;
  padding: 0 20px 0 20px !important;
}

.main_products_home1 {
  max-width: 100% !important;
  width: 100% !important;
  background-color: #eee !important;
  margin: auto;
}

.products_home1 {
  max-width: 1400px !important;
  margin: auto;
}

a:hover {
  text-decoration: none !important;
}

.promo_1 .ms-content-block__details {
  display: none !important;
}

.promo_1 {
  width: 1170px;
  margin: auto;
}

.products_home1 .msc-ss-carousel__flipper--next {
  top: 170px !important;
  left: 1346px !important;
  font-size: 31px;
  background-color: #fff !important;
  border-radius: 50%;
  padding: 15px !important;
  width: 60px;
  height: 60px;
}

.products_home1 .msc-ss-carousel__flipper {
  top: 170px !important;
  left: 0px;
  font-size: 31px;
  background-color: #fff !important;
  border-radius: 50%;
  padding: 15px !important;
  width: 60px;
  height: 60px;
}

.content_tile1 h1 {
  position: absolute;
  top: 5px;
  font-size: 4.6vw;
  text-align: center;
  color: #fff4e2;
  text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    -1px -1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px 0px 0 #000,
    0px -1px 0 #000, 4px 4px 0px #000;
  line-height: 0.8em;
  font-weight: 800;
  left: 50px;
}

.home_accord .drawer__button {
  width: 175px !important;
  margin: auto !important;
  border: 0px !important;
  font-size: 14px;
  text-decoration: underline;
  padding-left: 40px;
  height: 55px !important;
}

.home_accord .ms-accordion-item__drawer .drawer__button[aria-expanded="false"]:after {
  display: none !important;
}

.home_accord .ms-accordion-item__drawer .drawer__button[aria-expanded="true"]:after {
  display: none !important;
}

.home_accord .drawer {
  border: none !important;
}

.home_accord .fontsize__small p {
  max-width: 1200px !important;
  font-size: 11px;
  margin: auto;
  color: #8e8083 !important;
}

footer {
  margin-top: 30px !important;
}

body,
.ms-cart-icon__flyout-container .msc-cart-line__product-title {
  background-color: #fff !important;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
}

body {
  outline: none !important;
}

body p {
  font-family: "Inter", sans-serif !important;
}

.plp_list1 .msc-choice-summary {
  margin-top: 0px !important;
}

.plp_list1 .msc-choice-summary .msc-choice-summary__clear-all,
.plp_list1 .msc-choice-summary .msc-choice-summary__item {
  background: transparent !important;
  font-size: 13px !important;
}

.ms-header .ms-header__preferred-store-btn,
.ms-header .ms-search__icon,
.ms-header .ms-header__signin-button,
.ms-header .msc-wishlist-icon,
.ms-header .msc-cart-icon,
.ms-header .ms-header__profile-button,
.ms-content-block[data-m-layout="tile"] .msc-cta__primary {
  font-family: "Inter", sans-serif !important;
}

.img_list1 ul li {
  display: inline-block !important;
  text-align: center;
  padding: 15px;
}

.img_list1 img {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 50%;
  padding: 3px;
  /* border: 3px solid #d43494; */
}

.img_list1 {
  max-width: 1192px !important;
  margin: auto;
  padding-bottom: 15px;
}

.img_listing_h h2 {
  margin-top: 5px !important;
  color: #383838 !important;
  padding-bottom: 40px;
}

.img_list1 h3 {
  margin-top: 10px;
  text-transform: uppercase;
}

.img_listing_h .ms-text-block {
  margin-top: 0px !important;
}

.main_products_home1 .msc-ss-carousel-item .ms-product-collection__item {
  text-align: center;
}

.main_products_home1 h4 {
  text-transform: uppercase !important;
  font-size: 12px !important;
  margin-bottom: 0px;
  text-align: center;
  line-height: 15px !important;
}

.main_products_home1 .msc-price__actual {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.main_products_home1 .msc-product__details {
  text-align: center;
  margin: 0px !important;
}

.ms-promo-banner__link {
  font-size: 14px !important;
}

.page_container {
  max-width: 1400px !important;
  margin: auto;
}

.search_1 {
  max-width: 100% !important;
  margin: auto;
}

.pmk_breadcrum {
  background-color: #e5e5e5 !important;
}

.pmk_breadcrum .ms-breadcrumb_list {
  margin-left: 185px;
}

.ms-breadcrumb .ms-breadcrumb_item .ms-breadcrumb_link {
  font-size: 11px !important;
  font-family: inherit !important;
  color: #8e8083 !important;
  padding-top: 10px;
}

.ms-breadcrumb .ms-breadcrumb_item {
  font-family: inherit !important;
  font-size: 11px !important;
}

.ms-breadcrumb_link {
  font-size: 11px !important;
}

.ms-search-result__collection-title-prefix {
  font-size: 20px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  margin: 0 !important;
}

.ms-search-result__collection-title-text {
  font-size: 20px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  margin: 0 !important;
}

.ms-search-result__collection-title-count {
  font-size: 12px !important;
}

.plp_list1 .ms-product-search-result__item a .msc-product__title {
  font-size: 12px !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

.plp_list1 .msc-product__details {
  text-align: center !important;
  font-size: 15px !important;
}

.msc-rating__group {
  width: 100%;
}

.ms-footer__link a div {
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: #8e8083;
  line-height: normal !important;
}

footer .ms-footer__heading__title {
  font-size: 14px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 2px !important;
}

footer .ms-footer__link div,
footer .ms-footer__link__text {
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: #8e8083;
}

footer .ms-footer__item {
  padding: 0px !important;
}

.footer_box1 h2 a {
  text-decoration: none !important;
  color: #231f20 !important;
}

.footer_box4 h2 a {
  text-decoration: none !important;
  color: #231f20 !important;
}

.footer_box4 h2 {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 110px -795px;
  background-repeat: no-repeat;
  display: block;
  background-size: 15%;
  padding: 19px 0 10px 0px;
}

.footer_box5 {
  min-height: auto !important;
}

.footer_box5 .ms-content-block__text {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 0px -815px;
  background-repeat: no-repeat;
  display: block;
  background-size: 25%;
  padding: 10px 0 5px 38px;
}

.footer_box6 .ms-content-block__text {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 1px -866px;
  background-repeat: no-repeat;
  display: block;
  background-size: 25%;
  padding: 25px 0 0px 38px;
}

.footer_box6 .ms-content-block__cta {
  padding-left: 40px !important;
  text-transform: uppercase !important;
}

.footer_box4 {
  padding-left: 30px;
}

.footer_box1::before {
  content: "";
  height: 66px;
  border-right: 1px solid #d5d5d5;
  right: 0;
  position: absolute;
  top: 30px;
  width: 0;
}

.loc1 .ms-content-block__text p {
  width: 200px;
}

.footer_area1 p a {
  color: #8e8083 !important;
  text-decoration: underline !important;
}

.footer_area1 .row {
  justify-content: normal !important;
  padding: 0px !important;
}

.footer_area1 .col-12 {
  max-width: 400px !important;
  padding-left: 15px;
  padding-bottom: 0px;
}

.footer_area1 {
  max-width: 1200px !important;
  padding: 35px 0 15px 0 !important;
  border-bottom: 1px solid #d5d5d5;
}

.ms-subscribe__details__form {
  border: 1px solid #7c7c7c !important;
  width: 275px !important;
  background-color: #fff !important;
  padding-left: 5px !important;
  margin-top: 15px !important;
}

.ms-subscribe__details__form__email {
  padding: 0.5em !important;
  width: 230px !important;
  border: none !important;
  color: #000 !important;
  font-size: 13px !important;
}

.ms-subscribe__details__form__submit {
  font-size: 0px !important;
  border-left: solid 1px #000;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  height: 20px !important;
  width: 30px !important;
  background: transparent !important;
}

ul.social-items1 {
  width: 240px;
  margin: auto;
  padding-bottom: 30px !important;
}

li.social-items1 {
  display: inline-block;
  padding: 20px !important;
}

.footer_area2 {
  max-width: 1200px !important;
  padding: 0px !important;
}

.footer_area2 .row {
  padding-bottom: 0px !important;
}

.footer_area2 .row .col-12 {
  padding-bottom: 0px !important;
}

.footer_area_nav {
  background: transparent !important;
  margin-bottom: 80px !important;
}

.footer_area_nav ul {
  background: transparent !important;
}

.footer_area_nav ul li {
  background: transparent !important;
}

.footer_area_nav ul li a {
  background: transparent !important;
  color: #8e8083 !important;
}

.footer_area_nav ul li a::before {
  display: none;
}

.footer_area_nav ul li a:hover {
  background: transparent !important;
  color: #8e8083 !important;
  border: none !important;
}

.footer_area_nav .ms-nav__list {
  background: transparent !important;
}

.footer_area_nav .ms-nav__list__item__link {
  background: transparent !important;
  display: inline-block !important;
  height: 20px !important;
  line-height: normal !important;
  font-size: 11px !important;
  text-transform: uppercase !important;
}

.footer_area_21 .col-md-6 {
  max-width: 100% !important;
  flex: 100% !important;
}

.footer_area_21 h3 {
  font-size: 14px !important;
  color: #231f20 !important;
  font-weight: normal !important;
}

.footer_area_21 {
  padding: 0px !important;
}

.footer_box5 p {
  font-size: 11px !important;
  color: #8e8083 !important;
  font-weight: normal !important;
  width: 235px !important;
}

.footer_area_21 .ms-content-block {
  min-height: auto !important;
}

.footer_box5 h2 {
  font-size: 18px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  width: 235px !important;
}

.feed_back1 h5 a {
  border: 1px solid #000;
  background-color: #000;
  font-family: "Trebuchet ms", sans-serif;
  font-weight: 700;
  letter-spacing: normal;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 14px !important;
  padding: 8px 10px 8px 40px !important;
  margin-top: 10px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEifx.png);
  background-repeat: no-repeat;
  background-position: 5px 7px;
  text-decoration: none !important;
  background-size: 19%;
}

.feed_back1 h5 a:hover {
  border: 1px solid #000;
  background-color: #fff;
  font-family: "Trebuchet ms", sans-serif;
  font-weight: 700;
  letter-spacing: normal;
  color: #000;
  border-radius: 0;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 14px !important;
  padding: 8px 10px 8px 40px !important;
  margin-top: 10px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEasq.png);
  background-repeat: no-repeat;
  background-position: 5px 7px;
  text-decoration: none !important;
  background-size: 19%;
}

.footer_box6 h2 {
  font-size: 14px !important;
  color: #231f20 !important;
  font-weight: normal !important;
}

.footer_box6 p {
  font-size: 11px !important;
  color: #8e8083 !important;
  font-weight: normal !important;
}

/*Homepage end*/

.page_container_pdp_pmk1 .ms-media-gallery.vertical .ms-media-gallery__thumbnail-item .msc-empty_image {
  border: 2px solid #6f6f6f !important;
}

.page_container_pdp_pmk1 .ms-buybox__media-gallery {
  height: 565px !important;
}

.page_container_pdp_pmk1 nav {
  border-top: 1px solid #e5e5e5 !important;
  margin: 20px 0 !important;
}

.page_container_pdp_pmk1 .ms-buybox {
  margin: 0px !important;
}

.page_container_pdp_pmk1 {
  max-width: 1330px !important;
}

.page_container_pdp_pmk1 .ms-buybox__media-gallery {
  flex-basis: 55% !important;
  min-width: 55% !important;
  flex-grow: 0 !important;
}

.page_container_pdp_pmk1 .ms-buybox__product-title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Inter", sans-serif !important;
  margin: 0px;
  line-height: 2rem;
}

.page_container_pdp_pmk1 .msc-price__actual {
  font-size: 1.5em;
  font-family: "Inter", sans-serif !important;
  font-weight: 700;
  margin-top: 0px !important;
  display: inline-block;
}

.page_container_pdp_pmk1 .msc-price__actual::after {
  content: "\2713";
  margin-left: 10px;
  font-size: 15px;
}

.page_container_pdp_pmk1 .ms-buybox__content {
  width: 45% !important;
}

.page_container_pdp_pmk1 .ms-media-gallery__thumbnail {
  width: 65px !important;
  max-width: 65px !important;
  height: 100% !important;
}

.ms-buybox .product-add-to-cart .msc-add-to-cart:hover,
.ms-buybox .product-add-to-cart .msc-add-to-cart:focus {
  border: none !important;
  background-color: #bf0072 !important;
}

.ms-buybox .product-add-to-cart .msc-add-to-cart {
  background-color: #ec008d !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border: none !important;
  padding-bottom: 20px !important;
}

.page_container_pdp_pmk1 .msc-dropdown__select {
  border: solid 1px #ccc;
  font-size: 16px !important;
}

.page_container_pdp_pmk1 .msc-add-to-wishlist {
  position: absolute !important;
  top: 10px !important;
  left: 600px !important;
  background: transparent !important;
  border: none !important;
  color: #000 !important;
}

.ms-buybox__product-quantity-label,
.ms-buybox .quantity .quantity-input {
  font-size: 13px !important;
}

.page_container_pdp_pmk1 .fa-plus:before {
  display: none !important;
}

.page_container_pdp_pmk1 .fa-minus:before {
  display: none !important;
}

.ms-header__preferred-store-text {
  font-size: 0px !important;
}

.ms-header__signin-button-text {
  font-size: 0px !important;
}

.ms-header__signin-button:before {
  font-family: "Font Awesome 5 Free";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1;
  content: "";
}

.ms-header__preferred-store-btn {
  padding: 6px 0px 0px 0px !important;
}

.ms-header__signin-button {
  padding: 0px !important;
}

.msc-wishlist-icon {
  padding: 0px !important;
  margin: 0px !important;
}

.ms-header .ms-header__wishlist-desktop::before {
  font-size: 20px !important;
  content: "\f004" !important;
}

.ms-header .ms-header__wishlist-desktop {
  width: 24px !important;
  margin: 0 5px !important;
}

.ms-header .ms-header__preferred-store-btn:before {
  display: none !important;
}

.ms-header__preferred-store-btn {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  width: 20px !important;
  height: 30px !important;
  background-size: 277%;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.msc-cart-icon {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 0px -107px;
  width: 30px !important;
  background-size: 118%;
  height: 38px !important;
}

.msc-cart-icon__count {
  background-color: #cb0202;
  border-radius: 100%;
  color: #fff;
  padding: 3px 5px;
  font-size: 11px !important;
}

.msc-add-to-cart__dialog__body a,
.msc-add-to-cart__dialog__back-to-shopping {
  font-size: 14px !important;
  font-weight: bold !important;
}

.ms-header .msc-cart-icon::before {
  display: none !important;
}

.pdp_pmk_accordion {
  overflow: hidden;
  width: 580px !important;
  margin: 0px !important;
  padding: 0px 0px 100px 0px !important;
  float: right;
}

.pdp_pmk_accordion .drawer__button {
  font-size: 1rem;
  cursor: pointer;
  background-color: #e6e7e9;
  border-radius: 4px;
  position: relative;
  font-weight: 700;
  font-family: "Inter", sans-serif !important;
  width: 100%;
  text-align: left;
  margin-bottom: 3px;
  border: none !important;
}

.footer_pdp_carousel1 {
  position: relative;
  margin-top: 85px !important;
}

.footer_pdp_carousel1 .ms-product-collection {
  margin: 0px !important;
}

.msc-wishlist-icon .ms-header__wishlist-desktop {
  margin: 0 5px !important;
}

.ms-header__signin-button::before {
  content: "\f007" !important;
}

.footer_pdp_carousel1 .msc-ss-carousel-item .ms-product-collection__item {
  margin: 0px !important;
}

.footer_pdp_carousel1 .msc-ss-carousel__flipper--next {
  top: 170px !important;
  left: 1346px !important;
  font-size: 31px;
  background-color: #fff !important;
  border-radius: 50%;
  padding: 15px !important;
  width: 60px;
  height: 60px;
}

.footer_pdp_carousel1 .msc-ss-carousel__flipper {
  top: 170px !important;
  left: 0px;
  font-size: 31px;
  background-color: #fff !important;
  border-radius: 50%;
  padding: 15px !important;
  width: 60px;
  height: 60px;
}

.footer_pdp_carousel1 h4 {
  text-transform: uppercase !important;
  font-size: 12px !important;
  margin-bottom: 0px;
  text-align: center;
  line-height: 15px !important;
}

.footer_pdp_carousel1 .msc-price__actual {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.footer_pdp_carousel1 .msc-product__details {
  text-align: center;
  margin: 0px !important;
}

.footer_pdp_carousel1 {
  max-width: 100% !important;
  width: 100% !important;
  background-color: #eee !important;
  margin: auto;
  padding: 15px 0 15px 0 !important;
}

.footer_pdp_carousel1 .row {
  max-width: 1400px !important;
  width: 1400px !important;
  margin: auto !important;
  display: block !important;
}

.footer_pdp_carousel1 .row h2 {
  font-weight: normal !important;
  font-size: 1.3em;
  text-align: center !important;
}

.page_container_pdp_pmk1 .ms-social-share-item {
  display: block !important;
  overflow: inherit !important;
}

.page_container_pdp_pmk1 .ms-social-share-item {
  max-width: 50px !important;
  width: 50px !important;
}

.page_container_pdp_pmk1 .ms-social-share-item img {
  height: 32px !important;
}

.page_container_pdp_pmk1 .ms-social-share {
  width: 160px !important;
  margin: auto !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.page_container_pdp_pmk1 .ms-social-share-li {
  width: auto !important;
  height: auto !important;
}

.ms-cart-icon {
  padding-left: 0px !important;
}

.ms-header__divider {
  display: none !important;
}

.ms-site-picker {
  margin-left: 0px !important;
}

.ms-site-picker__button {
  margin: 0px !important;
  padding: 0px !important;
}

.ms-site-picker {
  width: 30px !important;
  margin: 0px !important;
}

.ms-site-picker__list__item__img {
  padding: 0px !important;
  width: 50px;
}

.ms-site-picker__list {
  width: 75px !important;
}

.ms-subscribe__details__form__submit {
  font-size: 0px !important;
  padding: 0px !important;
}

.ms-subscribe__details__form__submit::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "OK";
  width: 25px;
  text-align: center;
  font-size: 14px;
}

.footer_area2 .footer .ms-footer>*>*:nth-child(4) .ms-footer__list {
  display: block !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list-item:nth-child(1) {
  position: unset !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list-item:nth-child(2) {
  position: unset !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list-item:nth-child(3) {
  position: unset !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list-item:nth-child(4) {
  position: unset !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list-item:nth-child(5) {
  position: unset !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list-item:nth-child(6) {
  position: unset !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list {
  display: block !important;
}

footer .ms-footer>*>*:nth-child(4) .ms-footer__list {
  display: block !important;
}

.ms-footer__list {
  display: block !important;
}

.ms-buybox__dropdown-quantity-label {
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
}

#msc-dropdown__entry-3 option:first-child {
  background-color: red;
}

.ms-media-gallery .ms-media-gallery__item .msc_image {
  width: 550px !important;
  height: 650px !important;
  border: solid 1px #ccc !important;
}

.lie_container1 {
  max-width: 1024px !important;
}

.lie_container1 h1 {
  font-size: 38px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin-bottom: 30px !important;
}

.lie_container1 table {
  width: 100% !important;
  border-collapse: collapse !important;
}

.lie_container1 table th {
  width: 163px !important;
  padding: 20px 30px !important;
  text-align: left !important;
  vertical-align: middle !important;
  border: 1px solid #e4e4e4 !important;
  height: 50px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  display: table-cell;
  text-transform: uppercase !important;
  background-color: #fff !important;
}

.lie_container1 table thead tr {
  text-transform: uppercase !important;
}

.lie_container1 table tbody tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.lie_container1 table tbody tr td {
  padding: 20px 30px !important;
  text-align: left !important;
  vertical-align: middle !important;
  border: 1px solid #e4e4e4 !important;
  height: 50px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  display: table-cell;
  text-transform: uppercase !important;
}

.lie_container1 table tbody tr {
  height: 50px !important;
}

.bottom_note1 p {
  font-size: 13px !important;
  line-height: 19px !important;
}

.msc-add-to-cart__dialog__go-to-cart {
  background-color: #bf0072 !important;
  border: none !important;
  float: right !important;
  width: 49% !important;
}

.msc-btn {
  font-family: "Inter", sans-serif !important;
  text-transform: uppercase !important;
}

.msc-modal__dialog .msc-add-to-cart__dialog {
  max-width: 800px !important;
}

.msc-add-to-cart__dialog__back-to-shopping {
  float: left !important;
  width: 49% !important;
}

.msc-modal__title {
  font-size: 2em !important;
  margin-bottom: 15px !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-family: "Inter", sans-serif !important;
  line-height: 0.8em !important;
}

.msc-add-to-cart__dialog__header .msc-modal__header {
  display: block !important;
}

.msc-modal__header .msc-modal__close-button:before {
  position: relative !important;
  top: -20px !important;
}

.msc-modal .msc-add-to-cart__dialog {
  max-width: 800px !important;
}

.msc-add-to-cart__dialog__header .msc-modal__header {
  display: block !important;
  text-align: center !important;
}

.msc-modal .msc-add-to-cart__dialog .msc-modal__content {
  padding: 50px !important;
}

.lie_container2 h1 {
  font-size: 38px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin-bottom: 30px !important;
  padding: 0 30px;
}

.lie_container2 p {
  margin-bottom: 24px;
  line-height: 19px;
  font-size: 13px;
  color: #231f20;
}

.lie_container2 h2 {
  text-transform: uppercase;
  color: #745c1c;
  font-size: 16px;
  margin-bottom: 20px !important;
}

.lie_container2 h2 a {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 0px !important;
}

.lie_container2 a {
  padding-bottom: 20px;
}

.lie_container2 h3 a {
  text-decoration: underline;
  margin: 0px !important;
  padding: 0px !important;
}

.lie_container2 h3 {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.lie_container2,
.lie_container3 {
  max-width: 1024px !important;
}

.msc-cart-lines-item {
  margin: 5px 0 !important;
  padding: 5px !important;
  min-height: 220px;
}

.ms-cart-icon__flyout-container .msc-cart-line__product-savings,
.ms-cart-icon__flyout-container .msc-cart-line__quantity,
.ms-cart-icon__flyout-container .msc-cart-line__product-variants {
  margin: 0px !important;
}

.ms-image-list__list li {
  display: inline-block !important;
  padding-right: 25px !important;
}

.si_header_area {
  padding: 50px 0 !important;
  background-color: #fee9e9 !important;
  margin-bottom: 50px !important;
}

.si_header_area h2 {
  font-family: "dincond-bold";
  font-weight: 100;
  text-transform: uppercase;
  font-size: 30px;
  color: black;
  line-height: 1;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.si_header_area h3 {
  font-family: "dincond-bold";
  font-weight: 100;
  font-size: 20px;
  color: black;
  max-width: 800px;
  margin: 0 auto;
  text-transform: initial;
  text-align: center;
  line-height: 1.5;
}

.lie_container3 h2 {
  font-family: "dincond-bold";
  font-weight: 100;
  text-transform: uppercase;
  font-size: 30px;
  color: black;
}

.lie_container3 p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
}

.Footer_page_breadcrum .ms-breadcrumb {
  max-width: 1315px !important;
  margin: auto !important;
}

.ms-refine-submenu__toggle_collapsed:after {
  content: "\f107" !important;
  font-size: 20px;
}

.ms-refine-submenu__toggle_expanded:after {
  content: "\f106" !important;
  font-size: 20px;
}

.ms-search-result-container__Sort-by-category {
  font-family: "Inter", sans-serif !important;
  font-size: 12px;
  text-transform: uppercase;
  text-align: right;
  width: 320px;
}

.ms-search-result-container__Sort-by-category select {
  font-family: "Inter", sans-serif !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  font-weight: normal !important;
}

.ms-profile-button-text {
  font-size: 13px !important;
}

.ms-promo-banner {
  background-color: #000 !important;
}

.ms-promo-banner__links .ms-promo-banner__link:nth-child(1) {
  text-decoration: none !important;
}

.ms-promo-banner__links .ms-promo-banner__link:nth-child(2) {
  border-right: 1px solid #fff !important;
  padding-right: 150px;
  padding-left: 25px;
  text-decoration: none !important;
}

.ms-promo-banner__links .ms-promo-banner__link:nth-child(2)::before {
  content: "-";
  padding-right: 20px;
}

.ms-promo-banner__links .ms-promo-banner__link:nth-child(3) {
  padding-left: 150px;
}

.ms-promo-banner__links .ms-promo-banner__link:nth-child(4) {
  text-decoration: underline !important;
}

.ms-promo-banner {
  padding: 2px 0 !important;
}

.ms-promo-banner__link {
  text-decoration: none !important;
}

.ms-header__profile-button {
  padding: 0px !important;
}

.footer_area_nav .ms-nav__list__item {
  margin: 0px !important;
  padding: 0px 15px !important;
  background: transparent !important;
  line-height: normal !important;
}

.footer_area_nav .ms-nav__list__item::after {
  content: "|";
  font-size: 10px;
  padding-right: 5px;
  margin: 0px !important;
  padding-left: 5px;
  color: #d5d5d5;
  margin-bottom: 80px !important;
}

a,
a:active,
a:focus {
  outline: 0 !important;
  border: none !important;
  -moz-outline-style: none !important;
}

button:hover,
button:focus {
  outline: none !important;
}

.footer_area_nav ul li:last-child::after {
  display: none !important;
}

.ms-cart-icon__btn-checkout {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.ms-header__account-info {
  margin: 0 5px !important;
}

.ms-header .ms-header__account-info .msc-popover .msc-btn,
.ms-header .ms-header__account-info a {
  font-size: 12px !important;
}

.footer_box6 .msc-cta__primary {
  text-decoration: none;
  color: #8e8083 !important;
  font-size: 11px !important;
}

.footer_box6 .msc-cta__primary:hover {
  text-decoration: none;
}

.contact_1,
.contact_2 {
  max-width: 980px !important;
  margin: auto !important;
  padding: 0px !important;
}

.contact_2 h1 {
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 38px !important;
  text-align: center !important;
  margin-bottom: 25px !important;
}

.contact_2 h3 {
  color: #745c1c !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin: 0 8px 35px 8px !important;
  text-align: center !important;
}

.contact_area {
  max-width: 980px !important;
  margin: auto;
}

.guide-page-shop-button {
  text-align: center !important;
}

.guide-page-shop-button a {
  display: inline-block !important;
  padding: 8px 60px 12px 60px !important;
  border: 3px solid #000000 !important;
  color: #000000 !important;
  font-size: 20px !important;
  line-height: 22px !important;
  text-decoration: none !important;
}

.guide-page-shop-button a:hover {
  text-decoration: none !important;
}

.contact_area .col-12:nth-child(1) {
  max-width: 57% !important;
}

.contact_area .col-12:nth-child(2) {
  max-width: 43% !important;
  border-left: 1px solid #e5e5e5;
  padding-left: 25px !important;
}

.contact_box2 {
  font-size: 13px !important;
  color: #231f20 !important;
}

.contact_box2 ul {
  padding: 0px !important;
}

.contact_box2 ul li {
  list-style: none !important;
  border-bottom: 1px solid #d8d8d8 !important;
  margin-bottom: 15px !important;
  padding: 0 45px 20px 75px !important;
}

.contact_box2 ul li:nth-child(1) {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: -10px -1555px;
  background-repeat: no-repeat;
  background-size: 25%;
  padding-top: 15px !important;
}

.contact_box2 ul li:nth-child(2) {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: -15px -2015px;
  background-repeat: no-repeat;
  background-size: 30%;
}

.contact_box2 ul li:nth-child(3) {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: -6px -800px;
  background-repeat: no-repeat;
  background-size: 25%;
  padding-top: 15px !important;
}

.contact_box2 p a {
  display: block !important;
}

.faq_1 {
  max-width: 980px !important;
  margin: auto !important;
}

.faq_1 h1 {
  font-size: 38px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin-bottom: 30px !important;
}

.faq_1 .ms-accordion-item__drawer {
  border: none !important;
}

.faq_1 .ms-accordion-item__drawer .drawer__button[aria-expanded="false"]:after {
  position: absolute !important;
  right: 25px;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEasS.png);
  background-position: 0px -145px;
  background-repeat: no-repeat;
  color: #f6f6f6 !important;
  width: 25px;
  content: "." !important;
  font-size: 10px !important;
}

.faq_1 .ms-accordion-item__drawer .drawer__button[aria-expanded="true"]:after {
  position: absolute !important;
  right: 25px;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEasS.png);
  background-position: -33px -145px;
  background-repeat: no-repeat;
  color: #f6f6f6 !important;
  width: 25px;
  content: "." !important;
  font-size: 10px !important;
}

.faq_1 .ms-accordion-header-section {
  display: none !important;
}

.faq_1 .ms-accordion-item-content .drawer__button {
  background-color: transparent !important;
  padding: 0 !important;
  text-align: left !important;
  font-size: 16px !important;
  color: #745c1c !important;
  text-transform: uppercase !important;
  height: 36px !important;
}

.faq_1 .ms-accordion-item-content .drawer__button::after {
  right: -118px !important;
}

.faq_1 .ms-accordion-item-content {
  background-color: #fff;
  padding: 36px 0;
  max-width: 670px;
  margin: 0 auto;
}

.faq_1 .show .ms-accordion-item-content .ms-text-block p {
  margin-bottom: 24px;
  line-height: 19px;
  color: #231f20 !important;
  font-size: 13px !important;
}

.gut_container1 {
  max-width: 1015px !important;
}

.gut_container1 h1 {
  border-bottom: 1px solid #000;
  text-align: left;
  width: 40%;
  margin: 0 0 20px 0;
  font-size: 38px;
  text-transform: uppercase;
  color: #231f20;
  font-weight: 400;
  line-height: normal;
}

.gut_container1 h2 {
  font-size: 23px !important;
  color: #000 !important;
}

.gut_container1 .ms-content-block__text ol {
  margin-bottom: 24px;
  line-height: 19px;
  padding: 0 20px !important;
}

.gut_container1 .ms-content-block__text ol li {
  font-weight: 100;
  font-size: 1.2em;
  width: 90%;
}

.gut_container1 .ms-content-block__text ol li::marker {
  font-weight: bold !important;
}

.gut_head_area {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEas7.png);
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px !important;
}

.gut_container1 h2 {
  font-size: 3em;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 5px;
}

.gut_container1 .ms-subscribe {
  background-color: #f8f8f8;
  padding: 25px 0 25px;
  height: 160px;
  margin-top: 25px;
}

.gut_container1 .ms-subscribe h3 {
  border-bottom: 1px solid #000;
  font-size: 38px;
  margin: auto 20px;
  /* font-family: "dincond-lightregular"; */
  font-family: "Inter", sans-serif;
  font-weight: 100;
  width: 40%;
  line-height: normal;
}

.gut_container1 .ms-subscribe .ms-subscribe__details__text {
  float: left;
  width: 50%;
  font-size: 13px !important;
}

.gut_container1 .ms-subscribe .ms-subscribe__details__text p {
  padding: 20px !important;
  font-size: 13px !important;
}

.gut_container1 .ms-subscribe .ms-subscribe__details__form {
  float: right;
  width: 50%;
  font-size: 13px;
  margin-right: 115px !important;
}

.gut_container1 #text-block__5 p {
  font-size: 11px !important;
  color: #8e8083 !important;
}

.gut_container1 .ms-subscribe .ms-subscribe__details__form__submit {
  background-color: #000 !important;
  margin: 0 px !important;
  padding: 0 px !important;
  height: 25 px !important;
  color: #fff;
  line-height: normal;
}

.gut_container1 .container {
  padding: 0px !important;
}

.gut_container1 .ms-accordion-item-content p {
  font-size: 13px !important;
  font-weight: 100;
}

.gut_container1 .ms-accordion-header-section {
  display: none !important;
}

.gut_box1 h2 {
  font-size: 32px !important;
  font-weight: 100 !important;
  border-bottom: 2px solid #000 !important;
  padding-bottom: 15px;
  padding-top: 10px;
}

.gut_box1 .ms-content-block__text {
  float: left !important;
  width: 50% !important;
}

.gut_box1 .ms-content-block__text p {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.gut_box1 .ms-content-block__cta {
  float: right;
}

.gut_box1 .ms-content-block__cta .msc-cta__primary {
  text-align: center;
  display: block;
  text-transform: uppercase !important;
  text-decoration: none !important;
  padding: 5px 35px !important;
  border: 2px solid #231f20 !important;
  position: relative !important;
}

.gut_container1 .ms-accordion-item__drawer .drawer__button {
  text-transform: unset !important;
  font-size: 16px;
}

.gut_container1 .ms-accordion-item__drawer {
  border: none !important;
}

.gut_box1 {
  min-height: auto !important;
  width: 100% !important;
}

.gut_container1 .ms-accordion-item__drawer .drawer__button {
  height: 45px !important;
}

.gut_container1 .ms-accordion-item-content {
  padding: 0px !important;
}

.gut_container1 .ms-accordion-item__drawer .drawer__button span {
  text-decoration: underline !important;
}

.gut_container1 .ms-accordion-item__drawer .drawer__button[aria-expanded="true"]::after,
.gut_container1 .ms-accordion-item__drawer .drawer__button[aria-expanded="false"]::after {
  display: none !important;
}

.gut_loy1 h2 {
  font-size: 32px !important;
  font-weight: 100 !important;
  padding-bottom: 30px !important;
}

.gut_container1 .ms-accordion-item-content {
  max-height: 250px;
  height: auto !important;
  margin-top: 20px !important;
  transition: max-height ease-out 1s;
  border: 1px solid #e5e5e5 !important;
  padding: 10px !important;
}

.trends {
  max-width: 1330px !important;
  margin: auto;
  color: #231f20;
}

.trends h1 {
  width: 690px;
  border-bottom: solid 1px #000;
  text-align: center !important;
  margin: auto !important;
}

.trends h1 a {
  text-decoration: none !important;
  font-size: 80px !important;
  text-transform: uppercase !important;
  font-weight: 100 !important;
  padding-bottom: 10px !important;
}

.trends h4 {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  font-size: 11px !important;
  color: #8e8083 !important;
  font-weight: normal !important;
}

.trends .ms-accordion-header-section {
  display: none !important;
}

.trends .drawer__button {
  width: 175px !important;
  margin: auto !important;
  border: 0px !important;
  font-size: 11px !important;
  text-decoration: underline;
  padding-left: 40px;
  height: 55px !important;
  text-transform: unset !important;
}

.trends .ms-image-list img {
  width: 310px !important;
  height: auto !important;
}

.trends .ms-image-list ul li {
  padding-right: 10px !important;
}

.my_account1_1 {
  max-width: 1200px !important;
  margin: auto !important;
}

.my_account1,
.my_account2,
.my_account3 {
  max-width: 980px !important;
  margin: auto !important;
}

.my_account3 .msc-address-form__input,
.msc-address-form__dropdown {
  border: solid 1px #ccc !important;
}

.my_account1 h2 {
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #231f20 !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

.my_account2 ul {
  background-color: #fff !important;
}

.my_account2 ul li .ms-nav__list__item__button {
  background-color: #fff !important;
  font-size: 14px !important;
}

.my_account2 .ms-nav__list__item__button .mas-nav__default {
  display: block !important;
  visibility: visible !important;
}

.my_account3 .ms-content-block__details {
  margin: auto !important;
  text-align: center !important;
}

.my_account3 .ms-content-block__cta {
  margin: auto !important;
  width: 280px !important;
}

.my_account3 .msc-address-list__button-add {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  display: inline-block !important;
  text-align: center !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  transition: 0.2s ease !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.my_account3 .ms-content-block__details .msc-cta__primary {
  width: 280px !important;
  border: 1px solid #231f20 !important;
  background-color: #fff !important;
  color: #231f20 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 10px !important;
  text-transform: uppercase !important;
  display: block !important;
  text-align: center !important;
}

.my_account3 .ms-content-block__details .msc-cta__primary:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.my_account1 p {
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: initial;
  padding-top: 15px;
}

.my_account2 {
  min-height: 300px !important;
}

.my_account3 .ms-content-block {
  min-height: 100px !important;
}

.ms-nav__list__item div {
  display: block !important;
}

.cart_1 {
  max-width: 1200px !important;
}

.cart_1 h1 {
  font-size: 39px !important;
  padding: 20px 0 30px !important;
  font-family: "Inter", sans-serif !important;
}

.msc-cart-line__extra-actions {
  padding-top: 25px !important;
}

.cart_1 .msc-cart-line__product-quantity {
  flex: none !important;
  display: block !important;
  clear: both !important;
  position: absolute !important;
  left: 200px !important;
  top: 125px !important;
}

.cart_1 .msc-cart-line__product-quantity .decrement,
.cart_1 .msc-cart-line__product-quantity .increment {
  display: inline-block !important;
}

.cart_1 .msc-cart-lines-group-wraper__bopis-heading-envío {
  display: inline-block !important;
}

.cart_1 .msc-cart-lines-group-wraper__bopis-heading-title {

  margin-left: 6px !important;
  display: inline-block !important;
}

.msc-cart-line__product-quantity .quantity .decrement .fa-minus,
.msc-cart-line__product-quantity .quantity .increment .fa-plus {
  display: none !important;
}

.msc-cart-line__product-quantity-label {
  font-size: 13px !important;
  color: #7e7e7e !important;
}

.cart_1 .msc-cart__empty-cart .msc-cart-line__product-title {
  font-weight: normal !important;
  font-size: 16px !important;
  padding-bottom: 15px !important;
  text-transform: unset !important;
  text-decoration: none !important;
}

.cart_1 .msc-cart-line__product-savings {
  position: absolute !important;
  top: 180px !important;
  font-weight: normal !important;
  right: 0px;
  margin: 0px;
  padding: 0px;
}

.ms-buybox .quantity .quantity__controls {
  height: 20px !important;
  width: 20px !important;
  border: none !important;
}

.ms-buybox .quantity .increment:after {
  font-family: "Font Awesome 5 Free";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1;
  content: "";
}

.quantity .quantity-input {
  width: auto !important;
  padding: 0px !important;
  height: auto !important;
  border: 1px solid #b1b2b3 !important;
  font-size: 13px !important;
}

.msc-cart-line__product-quantity .quantity .decrement,
.msc-cart-line__product-quantity .quantity .increment {
  font-size: 13px !important;
  padding: 0px !important;
  margin: 0px !important;
  width: auto !important;
  padding: 10px !important;
  border: none !important;
}

.cart_1 .msc-order-summary-wrapper {
  width: 388px !important;
  display: block !important;
  flex: auto !important;
  background-color: #f5f5f5 !important;
  padding: 20px !important;
}

.cart_1 .msc-cartline-wraper {
  width: 730px !important;
  flex: none !important;
}

.cart_1 .msc-cart__btn-checkout {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  display: inline-block !important;
  text-align: center !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  transition: 0.2s ease !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.cart_1 h2 {
  text-transform: uppercase;
  font-weight: normal !important;
  font-size: 20px !important;
  font-family: "Inter", sans-serif !important;
}

.cart_1 .msc-promo-code__apply-btn {
  font-size: 0px !important;
  min-width: 55px !important;
  background-color: #fff !important;
  border: none !important;
}

.cart_1 .msc-promo-code__apply-btn::after {
  content: "OK";
  font-size: 13px;
  color: #000;
  border-left: solid 1px #000;
  padding-left: 10px;
  height: 40px;
  padding-top: 10px;
  font-weight: bold !important;
}

.cart_1 .msc-promo-code__form-container {
  background-color: #fff !important;
  border: 1px solid #b1b2b3 !important;
  padding: 5px !important;
  margin-top: 15px !important;
}

.cart_1 .msc-promo-code__input-box {
  border: none !important;
  font-size: 16px !important;
}

.cart_1 .msc-order-summary__items .msc-promo-code__group {
  padding: 0px !important;
}

.cart_1 .msc-order-summary__line-sub-total {
  margin: 30px 0 0 !important;
  border-top: 1px solid #b1b2b3 !important;
  padding-top: 25px !important;
  text-transform: uppercase !important;
}

.cart_1 .msc-order-summary__label {
  text-transform: uppercase !important;
}

.cart_1 .msc-order-summary__line-total {
  font-weight: bold !important;
  font-size: 20px !important;
}

.cart_1 .msc-cart-line__remove-item {
  font-size: 0px !important;
  position: relative !important;
  top: -200px !important;
  text-decoration: none !important;
  float: right !important;
}

._980 {
  max-width: 980px !important;
  width: 980px !important;
  margin: auto !important;
}

.cart_1 .msc-cart-line__remove-item::after {
  font-family: cursive !important;
  font-size: 16px;
  content: "X";
  color: #918f90;
  text-decoration: none !important;
}

.footer_area_cart {
  max-width: 1330px !important;
  margin: auto !important;
}

.footer_area_cart .row {
  display: inline !important;
}

.footer_area_cart .row .col-12 {
  float: left;
}

.footer_area_cart1 {
  text-align: center;
}

.footer_area_cart .footer_box1_cart a {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 0px -214px !important;
  background-repeat: no-repeat !important;
  background-size: 25% !important;
  height: 70px !important;
  width: 200px !important;
  display: block !important;
  padding-top: 70px !important;
}

.page_container_pdp_pmk1 .ms-fullscreen-section {
  height: 730px !important;
}

.pdp_pmk_accordion .ms-product-details {
  padding: 20px !important;
}

.cart_1 .msc-cart-line__product-variant-item {
  color: #7e7e7e;
  font-size: 13px !important;
}

.btn-secondary {
  border: solid 1px #000;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none !important;
  border-radius: 4px !important;
  text-transform: unset !important;
}

.cart_1 .ms-cart .msc-cartline-wraper {
  border: none !important;
}

.cart_1 .msc-cart-line__product {
  flex: none !important;
}

.msc-cart-line__product-variants,
.msc-cart-line__other-charges-label,
.msc-cart-line__freight-label,
.msc-cart-line .shipping-value,
.msc-cart-line .other-charge-value {
  font-family: "Inter", sans-serif !important;
}

.cart_1 .ms-cart .msc-cart__heading {
  margin: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  display: inline-block !important;
  text-align: center !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  transition: 0.2s ease !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.check_out1 {
  max-width: 1200px !important;
  margin: auto !important;
}

.check_out1 .msc-address-form__input,
.msc-address-form__dropdown {
  border: solid 1px #ccc !important;
}

.check_out1 .ms-checkout__guided-card {
  font-size: 13px !important;
}

.msc-cart-line__product-title,
.msc-price__actual {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  line-height: normal !important;
}

.check_out1 .ms-checkout__line-items-header a {
  display: block !important;
  float: none !important;
  text-align: left !important;
  font-size: 13px !important;
  padding-top: 10px !important;
}

.check_out1 .msc-cart-line__product-variants,
.check_out1 .msc-cart-line__product-price,
.check_out1 .msc-cart-line__quantity {
  font-size: 13px !important;
}

.check_out1 .msc-address-form__alert,
.page_container_pdp_pmk1 .msc-alert {
  border: none !important;
  color: red !important;
  background-color: #fff !important;
  font-weight: normal !important;
  padding: 0 !important;
}

.check_out1 .ms-checkout__line-items-header {
  margin: 0px !important;
}

.check_out1 .ms-checkout__line-items,
.check_out1 .msc-order-summary-wrapper {
  padding: 20px !important;
}

.msc-cart-line__remove-item {
  font-size: 13px !important;
  text-transform: unset !important;
}

.msc-cart-line__product-title {
  font-size: 11px !important;
  text-transform: uppercase !important;
  text-decoration: underline !important;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
  max-width: 450px;
}

.search_1 .ms-search-result-container__category-nav-section {
  display: none;
}

.page_container_pdp_pmk1 .product-add-to-cart .show {
  display: none !important;
}

.cart_1 .msc-cart__empty-cart {
  width: 1200px !important;
  margin: auto !important;
}

.cart_1 .msc-cart-line {
  margin: auto;
  padding: 20px !important;
  font-size: 39px;
  font-weight: 400 !important;
  padding: 20px 0 30px !important;
  margin-bottom: 50px !important;
  line-height: normal !important;
}

.cart_1 .msc-cart__btn-backtoshopping {
  background-color: #fff !important;
  color: #231f20 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;

  border: none !important;
  display: block !important;
}

.ms-store-select__search-button {
  background-color: #ec008d !important;
}

.footer_area_cart .row:nth-child(1) {
  float: left !important;
}

.footer_area_cart .row:nth-child(2) {
  float: right !important;
}

.footer_box2_cart {
  text-align: right;
  margin-left: 500px;
}

.footer_box2_cart .ms-content-block__text {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 1px -1067px;
  background-repeat: no-repeat;
  display: block;
  background-size: 50%;
  padding: 5px 0 0px 38px;
  margin-top: 25px;
  width: 120px;
}

.footer_box2_cart .ms-content-block__details {
  border-left: 1px solid #d5d5d5;
  padding-left: 100px;
}

.footer_box2_cart h2 {
  font-size: 14px !important;
  color: #231f20 !important;
  font-weight: normal !important;
}

.footer_box2_cart a {
  text-decoration: none;
  color: #8e8083 !important;
  font-size: 11px !important;
}

.ms-site-picker__list {
  display: block !important;
}

input {
  outline: none !important;
}

button span {
  outline: none !important;
}

.main_carousel1 .msc-cta__primary {
  position: relative !important;
  bottom: -370px !important;
  color: #333 !important;
  background-color: #fff !important;
  border: 0 !important;
  width: auto !important;
  padding: 13px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-align: center !important;
  transition: all 0.2s !important;
  min-width: 205px !important;
  background-color: #fff !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
  border-radius: 3px !important;
  left: 410px !important;
  height: 44px !important;
}

.main_carousel1 a.msc-cta__primary:hover {
  text-decoration: none !important;
}

.footer_box1 .col-md-6,
.footer_box2 .col-md-6,
.footer_box3 .col-md-6 {
  max-width: 100% !important;
  flex: auto !important;
}

.footer_box1 .col-md-6 .ms-text-block,
.footer_box2 .col-md-6 .ms-text-block,
.footer_box3 .col-md-6 .ms-text-block {
  margin-top: 5px !important;
}

.footer_box2 h2 a,
.footer_box3 h2 a {
  text-decoration: none !important;
  color: #231f20 !important;
}

.footer_box2 .ms-content-block__text,
.footer_box3 .ms-content-block__text {
  padding-top: 24px !important;
}

.footer_box2::after {
  content: "";
  height: 66px;

  left: 0;
  position: absolute;
  top: 30px;
  width: 0;
}

.footer_box2::before {
  content: "";
  height: 66px;
  border-right: 1px solid #d5d5d5;
  right: 0;
  position: absolute;
  top: 30px;
  width: 0;
}

.footer_box2 .msc-cta__primary,
.footer_box3 .msc-cta__primary {
  font-size: 11px !important;
  color: #8e8083 !important;
}

.footer_box1 p,
.footer_box2 p,
.footer_box3 p,
.footer_box4 p {
  font-size: 11px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  color: #8e8083 !important;
}

.footer_box2 .msc-cta__secondary {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 200px -650px;
  background-repeat: no-repeat;
  display: block;
  background-size: 18%;
  padding: 15px 0 10px 0px !important;
  position: relative;
  top: -100px;
  left: -25px;
  text-decoration: none !important;
}

.footer_box2 h2 {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 226px -400px;
  background-repeat: no-repeat;
  display: block;
  background-size: 14%;
  padding: 20px 0 11px 0px;
}

.footer_box3 .msc-cta__secondary {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 140px -707px;
  background-repeat: no-repeat;
  display: block;
  background-size: 25%;
  padding: 0px 0 10px 0px;
  width: 200px;
  position: relative;
  top: -84px;
  left: -25px;
  text-decoration: none !important;
}

.footer_box3::before {
  content: "";
  height: 66px;
  border-right: 1px solid #d5d5d5;
  right: 0;
  position: absolute;
  top: 30px;
  width: 0;
}

footer a.ms-footer__link:hover {
  text-decoration: none !important;
}

.cart_1 .msc-order-summary__label,
.msc-order-summary__value {
  font-size: 14px !important;
}

.msc-cart__empty-cart .msc-cart__btn-backtoshopping {
  font-weight: 400 !important;
}

.msc-cart__empty-cart p {
  text-align: center !important;
  display: block !important;
}

.msc-add-to-cart::before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f07a";
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  padding-right: 10px;
  /* background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEifr.png);
    background-repeat: no-repeat;*/
}

.ms-media-gallery.vertical .ms-media-gallery__thumbnail-item .msc_image {
  width: 84px !important;
  height: 84px !important;
  object-fit: cover !important;
}

.pdp_pmk_accordion .drawer__button[aria-expanded="true"]:after {
  content: "-";
  font-size: 21px;
  position: absolute;
  right: 15px;
  top: 30%;
}

.pdp_pmk_accordion .drawer__button[aria-expanded="false"]:after {
  content: "+";
  font-size: 21px;
  position: absolute;
  right: 15px;
  top: 30%;
}

span.msc-wishlist-icon__text {
  display: none !important;
  font-size: 0px !important;
}

.ms-media-gallery__thumbnail-item-active img {
  border: 2px solid #6f6f6f !important;
}

.my_account2 {
  margin-top: 50px !important;
}

.my_account2 .msc-ss-carousel button {
  display: none !important;
}

.my_account2 h3 {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;

  width: 100%;
  /* 
    height: 32px;
    margin-bottom: 20px !important;
    padding-bottom: 52px !important;
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEazt.jpg); 
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;*/
}

.my_account2 a {
  text-transform: uppercase !important;
  font-size: 12px !important;
  display: block !important;
}

.my_account2 ul li {
  padding: 2px 0px 2px 0px !important;
}

.my_account2 ul li:last-child h3 {
  padding-bottom: 50px !important;
  background-size: 114%;
}

.plp_list1 .ms-refine-submenu__toggle_expanded {
  border: none !important;
}

.plp_list1 {
  max-width: 1200px !important;
  margin: auto !important;
}

.plp_list1 .ms-search-result-container__refiner-section .ms-refine-submenu.list-group:first-child {
  border-top: none !important;
}

.ms-search-result-container__refine-menu {
  width: 1200px !important;
  padding-bottom: 10px;
  padding-top: 40px !important;
  text-align: center !important;
  max-width: 100% !important;
}

.ms-refine-submenu-item .refine-submenu-item__rating {
  padding-right: 15px !important;
}

.ms-search-result-container__refiner-section button.ms-refine-submenu__toggle_collapsed,
.ms-search-result-container__refiner-section .ms-refine-submenu__toggle_expanded {
  font-size: 12px !important;
  display: block !important;
  text-transform: uppercase !important;
  padding: 5px 20px !important;
  height: 40px !important;
  text-align: left;
}

.ms-refine-submenu__toggle_collapsed:after,
.ms-refine-submenu__toggle_expanded:after {
  float: right !important;
  padding-top: 5px !important;
}

.ms-search-result-container__refiner-section .ms-refine-submenu.list-group .ms-refine-submenu-item .msc-rating__empty-star,
.ms-search-result-container__refiner-section .ms-refine-submenu.list-group .ms-refine-submenu-item .msc-rating__star,
.ms-search-result-container__refiner-section .ms-refine-submenu.list-group .ms-refine-submenu-item .msc-rating__count {
  font-size: 12px !important;
}

.ms-search-result-container__refiner-section .ms-refine-submenu.list-group .ms-refine-submenu-item .msc-rating {
  margin-bottom: 0px !important;
  line-height: 18px !important;
}

.ms-refine-submenu-item a {
  text-align: left;
  padding-left: 15px !important;
}

.ms-search-result-container__product-section .ms-search-result-wrapper-sort-by-category {
  top: -60px !important;
}

.ms-search-result-container__Products {
  margin-top: 0px !important;
}

.ms-search-result-wrapper-title-choice-summary h2 span {
  font-family: "Inter", sans-serif !important;
  font-size: 20px;
}

.ms-search-result-container__product-section .ms-search-result-wrapper-title-choice-summary {
  position: relative;
  top: -110px;
  text-align: center;
}

/*search  plp_main_2*/

.search_list1 .ms-search-result-container__refiner-section {
  margin-top: 135px !important;
}

.search_list1 .ms-search-result-wrapper-title-choice-summary {
  background-color: #e5e5e5 !important;
  left: 0 !important;
  padding-top: 35px !important;
  padding-bottom: 50px !important;
  position: absolute !important;
  width: 100% !important;
  max-width: 1600px !important;
  top: 10px !important;
}

.search_list1 .ms-search-result-container__Sort-by-category {
  position: absolute !important;
  top: 140px !important;
  right: -800px !important;
}

.search_list1 .ms-search-result-container__Products {
  margin-top: 145px !important;
}

/*search*/

.search_1 .pmk_breadcrum {
  min-height: 160px !important;
}

.cookies {
  max-width: 980px !important;
  margin: auto;
}

.policy_1 {
  max-width: 980px !important;
  margin: auto;
}

.cookies h1 {
  font-size: 38px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin: 30px !important;
}

.cookies h2 {
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #745c1c !important;
  margin-bottom: 20px !important;
}

.cookies p,
.cookies li {
  margin-bottom: 24px !important;
  line-height: 19px !important;
  font-size: 13px !important;
}

.cookies h3 {
  font-size: 13px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  margin-bottom: 20px !important;
}

.cookies table tr td {
  font-size: 13px !important;
  padding: 12px !important;
  border-spacing: 0 !important;
}

.cookies table {
  border-collapse: collapse !important;
  width: 100% !important;
  margin-bottom: 15px !important;
}

.cookies table tr:nth-child(1) td {
  background-color: #e5e5e5;
  text-align: center;
  font-weight: bold;
  padding: 1em;
  vertical-align: top;
}

.faq_1 table {
  border-collapse: collapse !important;
  width: 100% !important;
  margin-bottom: 15px !important;
}

.faq_1 table tr th:nth-child(1) {
  text-align: left !important;
}

.faq_1 table tr th {
  font-size: 13px !important;
  padding: 12px !important;
  border-spacing: 0 !important;
  width: 163px !important;
  padding: 15px 0px 15px 36px !important;
  text-align: center !important;
  vertical-align: middle !important;
  border: 1px solid #e4e4e4 !important;
  height: 36px !important;
  text-transform: uppercase !important;
  font-weight: normal !important;
}

/*
  .faq_1 .ms-accordion-item-content img {
    width: 90px !important;
    height: auto !important;
  }*/
.faq_1 table tr td {
  font-size: 13px !important;
  padding: 12px !important;
  border-spacing: 0 !important;
  width: 163px !important;
  padding: 15px 0px 15px 36px !important;

  vertical-align: middle !important;
  border: 1px solid #e4e4e4 !important;
  height: 36px !important;
  text-transform: uppercase !important;
}

.faq_1 table tbody tr:nth-child(odd) {
  height: 36px !important;
  border: solid 1px #e4e4e4;
}

.ms-accordion-item-content h3 {
  font-size: 13px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-bottom: 14px !important;
}

.ms-accordion-item-content h4 {
  font-size: 13px !important;
  font-weight: 700 !important;
  margin-bottom: 14px !important;
}

.policy_1 h1 {
  font-size: 38px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin: 30px !important;
}

.policy_1 h2 {
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #745c1c !important;
  margin-bottom: 20px !important;
}

.policy_1 p,
.policy_1 li {
  margin-bottom: 24px !important;
  line-height: 19px !important;
  font-size: 13px !important;
}

.ms-accordion-item-content ol {
  list-style: none !important;
}

.ms-accordion-item-content ol li {
  margin-bottom: 5px !important;
  line-height: 19px !important;
  font-size: 13px !important;
  list-style: none !important;
}

.policy_1 h3 {
  font-size: 13px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  margin-bottom: 20px !important;
}

.AGP_page1 ul {
  background-color: #fff;
  padding: 36px 0 !important;
  max-width: 670px !important;
  margin: 0 auto !important;
}

.AGP_page1 ul li {
  list-style: none !important;
  float: left;
  width: 335px;
}

.AGP_page1 ul li a {
  background-color: transparent;
  display: inline-block !important;
  margin-bottom: 14px !important;
  text-align: left !important;
  text-decoration: none !important;
  font-size: 16px !important;
  color: #745c1c !important;
  text-transform: uppercase;
  height: 36px !important;
}

.AGP_page1 .ms-accordion-item:nth-child(even) button {
  display: none !important;
}

.AGP_page1 .ms-accordion-item:nth-child(even) .show {
  min-height: auto !important;
}

.AGP_page1 .ms-accordion-item:nth-child(even) .show .ms-accordion-item-content {
  padding: 0px !important;
  margin: 0px !important;
}

.AGP_page1 .ms-accordion-item:nth-child(even) .ms-accordion-item__drawer {
  margin: 0px !important;
}

.AGP_page2 ul {
  background-color: #fff;
  padding: 36px 0 !important;
  max-width: 670px !important;
  margin: 0 auto !important;
}

.AGP_page2 ul li {
  list-style: none !important;
  float: left;
  width: 335px;
}

.AGP_page2 ul li a {
  background-color: transparent;
  display: inline-block !important;
  margin-bottom: 14px !important;
  text-align: left !important;
  text-decoration: none !important;
  font-size: 16px !important;
  color: #745c1c !important;
  text-transform: uppercase;
  height: 36px !important;
}

.AGP_page2 .ms-accordion-item:nth-child(even) button {
  display: none !important;
}

.AGP_page2 .ms-accordion-item:nth-child(even) .show {
  min-height: auto !important;
}

.AGP_page2 .ms-accordion-item:nth-child(even) .show .ms-accordion-item-content {
  padding: 0px !important;
  margin: 0px !important;
}

.AGP_page2 .ms-accordion-item:nth-child(even) .ms-accordion-item__drawer {
  margin: 0px !important;
}

.AGP_page3 ul {
  background-color: #fff;
  padding: 36px 0 !important;
  max-width: 670px !important;
  margin: 0 auto !important;
}

.AGP_page3 ul li {
  list-style: none !important;
  float: left;
  width: 335px;
}

.AGP_page3 ul li a {
  background-color: transparent;
  display: inline-block !important;
  margin-bottom: 14px !important;
  text-align: left !important;
  text-decoration: none !important;
  font-size: 16px !important;
  color: #745c1c !important;
  text-transform: uppercase;
  height: 36px !important;
}

.AGP_page3 .ms-accordion-item:nth-child(even) button {
  display: none !important;
}

.AGP_page3 .ms-accordion-item:nth-child(even) .show {
  min-height: auto !important;
}

.AGP_page3 .ms-accordion-item:nth-child(even) .show .ms-accordion-item-content {
  padding: 0px !important;
  margin: 0px !important;
}

.AGP_page3 .ms-accordion-item:nth-child(even) .ms-accordion-item__drawer {
  margin: 0px !important;
}

.AGP_page4 ul {
  background-color: #fff;
  padding: 36px 0 !important;
  max-width: 670px !important;
  margin: 0 auto !important;
}

.AGP_page4 ul li {
  list-style: none !important;
  float: left;
  width: 335px;
}

.AGP_page4 ul li a {
  background-color: transparent;
  display: inline-block !important;
  margin-bottom: 14px !important;
  text-align: left !important;
  text-decoration: none !important;
  font-size: 16px !important;
  color: #745c1c !important;
  text-transform: uppercase;
  height: 36px !important;
}

.AGP_page4 .ms-accordion-item:nth-child(even) button {
  display: none !important;
}

.AGP_page4 .ms-accordion-item:nth-child(even) .show {
  min-height: auto !important;
}

.AGP_page4 .ms-accordion-item:nth-child(even) .show .ms-accordion-item-content {
  padding: 0px !important;
  margin: 0px !important;
}

.AGP_page4 .ms-accordion-item:nth-child(even) .ms-accordion-item__drawer {
  margin: 0px !important;
}

.partner_1 {
  text-align: left !important;
  font-size: 13px !important;
}

.partner_1 h2 {
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #745c1c !important;
  margin-bottom: 20px !important;
}

.partner_1 h3 {
  font-size: 13px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-bottom: 14px !important;
}

.partner_1 ul li {
  list-style: none !important;
  line-height: 19px !important;
}

.partner_1 ul {
  padding: 0 10px !important;
}

.partner_1 a {
  display: block !important;
  width: 70% !important;
  height: auto !important;
  background-color: #000 !important;
  margin: 20px auto !important;
  padding: 8px 0 !important;
  text-align: center !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 2em !important;
  text-decoration: none !important;
  font-weight: bold !important;
  line-height: 1em !important;
}

.partner_1 a span::after {
  content: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEijv.gif);
  text-decoration: none;
  padding: 0 10px;
  line-height: 1em;
  display: inline-block;
  float: right;
}

.profile_1 {
  max-width: 980px !important;
  margin: auto !important;
}

.profile_1 h1 {
  font-size: 36px;
  padding: 1em 0;
  text-transform: uppercase;
  font-weight: 400;
}

.profile_1 .ms-account-profile__section-email,
.profile_1 .ms-account-profile__section-name {
  max-width: 490px !important;
  margin: auto !important;
}

.profile_1 .ms-account-profile__section-preferences {
  max-width: 490px !important;
  margin: auto !important;
}

.profile_2,
.profile_2_1 {
  margin-top: 50px !important;
  max-width: 980px !important;
  margin: auto !important;
  background-color: transparent !important;
}

.profile_2_1 .ms-store-locate .ms-store-select__search-button {
  height: 48px !important;
}

.profile_2 ul li {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-bottom: 50px !important;
  text-align: center !important;
  background-color: transparent !important;
  height: 32px !important;
  margin-bottom: 20px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEfH4.png);
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: 400% !important;
  width: 200px !important;
  margin-right: 17px !important;
}

.store_selector_2 ul li {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-bottom: 50px !important;
  background-color: transparent !important;
  height: 32px !important;
  margin-bottom: 20px !important;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: 400% !important;
  margin-right: 17px !important;
  background-image: none !important;
  width: 100% !important;
  text-align: left !important;
  border-bottom: 1px solid rgb(180, 180, 180);
}

.profile_2 .ms-nav>.ms-nav__list,
.profile_2 .ms-nav>.ms-nav__list>.ms-nav__list__item,
.profile_2 .ms-nav__list__item__link,
.profile_2 .ms-nav__list__item__button {
  background-color: transparent !important;
  font-size: 14px !important;
}

.profile_2 ul li ul {
  background-color: #fff !important;
  padding-bottom: 10px !important;
}

.profile_2 ul li ul li {
  background-image: none !important;
  background-color: #fff !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  text-align: left !important;
}

.profile_2 button .ms-nav__default {
  background-color: #fff !important;
}

.profile_2 .ms-nav__default ul li {
  padding-bottom: 5px !important;
  height: auto !important;
  margin-bottom: 2px !important;
}

.profile_1 .ms-account-profile {
  width: 490px !important;
  margin: auto !important;
  display: block !important;
}

.profile_1 .ms-account-profile .ms-account-profile-wrapper {
  margin: 0px !important;
}

.profile_1 .ms-account-profile .ms-account-loyalty-tile__heading::before {
  display: none !important;
}

.profile_1 .ms-text-block p {
  color: #6f6f6f !important;
  font-size: 11px !important;
  text-transform: none !important;
  width: 490px;
  margin: auto !important;
  padding-top: 15px !important;
}

.profile_1 .ms-account-profile__section h2 {
  font-family: "Inter", sans-serif !important;
  font-size: 13px;
  color: #b1b2b3;
  font-weight: 400 !important;
  margin: 0px !important;
}

.profile_1 .ms-account-profile__section p,
.profile_1 .ms-account-profile__section h3 {
  color: #231f20 !important;
  font-size: 16px !important;
  margin: 0px !important;
}

.profile_1 .ms-account-profile__section {
  font-family: "Inter", sans-serif !important;
  border: 1px solid #b1b2b3 !important;
  border-radius: 2px !important;
  margin-bottom: 15px !important;
  padding: 10px !important;
}

.profile_1 .ms-account-profile__preference {
  margin: 0px !important;
}

.profile_1 .ms-account-profile__section button {
  color: #98005b !important;
}

.policy_1 table tr td {
  border: solid 1px #222222 !important;
  font-size: 13px !important;
  padding: 10px !important;
  border-spacing: 0 !important;
}

.policy_1 table {
  border-collapse: collapse !important;
}

.loyalty_2 h3 {
  font-size: 24px !important;
  line-height: 26px !important;
  font-family: inter sans-serif !important;
  font-weight: 400 !important;
  text-transform: initial;
  margin: 0px !important;
  letter-spacing: 0 !important;
  background-color: #fce1da !important;
  padding: 15px !important;
  margin-bottom: 20px !important;
}

.loyalty_1 h3 {
  font-size: 24px !important;
  line-height: 26px !important;
  font-family: inter sans-serif !important;
  font-weight: 400 !important;
  text-transform: initial;
  margin: 0px !important;
  letter-spacing: 0 !important;
  background-color: #fce1da !important;
  padding: 15px 0px 0px 0px !important;
}

.loyalty_1 h3 span,
.loyalty_2 h3 span {
  color: #e94a38 !important;
}

.loyalty_1 h4 {
  background-color: #fce1da !important;
  padding: 0px 0px 15px 0px !important;
  font-size: 15px !important;
  line-height: 25px !important;
  font-family: inter, sans-serif !important;
  font-weight: 900 !important;
  margin: 0px !important;
}

.loyalty_1,
.loyalty_1 p,
.loyalty_2,
.loyalty_2 p,
.loyalty_3,
.loyalty_3 p {
  text-align: center !important;
  font-family: inter sans-serif !important;
}

.loyalty_3 #text-block div div,
.loyalty_3 #text-block__12 div div div {
  background-color: #fce1da !important;
  padding: 15px !important;
  box-sizing: border-box;
  text-align: center !important;
}

.loyalty_3 .ms-accordion-item-section .ms-accordion-item:nth-child(3) .ms-content-block__details {
  position: inherit !important;
  padding: 0px !important;
  width: auto !important;
  display: block !important;
}

.loyalty_3 .ms-accordion-item-section .ms-accordion-item:nth-child(3) .ms-content-block__details p {
  font-size: 16px !important;
}

.loyalty_3 .ms-accordion-item-section .ms-accordion-item:nth-child(3) .ms-content-block__details img {
  width: auto !important;
}

.loyalty_3 .default-container__0,
.loyalty_3 .default-container__1,
.loyalty_3 .default-container__2,
.loyalty_3 .default-container__3,
.loyalty_3 .default-container__4,
.loyalty_3 .default-container__5 {
  max-width: 47% !important;
  background-color: #fce1da;
  padding: 20px 10px;
  float: left;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
}

.loyalty_3 #text-block div div h3 {
  font-size: 24px !important;
  line-height: 26px !important;
  font-family: inter sans-serif !important;
  font-weight: 400 !important;
  text-transform: initial !important;
  margin: 0 !important;
  letter-spacing: 0 !important;
}

.loyalty_3 #text-block div div h3 span {
  color: #e94a38 !important;
}

.loyalty_3 .ms-content-block__image img {
  width: 55px !important;
  height: auto;
  display: inline !important;
}

.loyalty_3 #text-block div div h4 {
  font-size: 15px !important;
  line-height: 25px !important;
  font-family: inter, sans-serif !important;
  font-weight: 900 !important;
  margin: 0 !important;
}

.loyalty_3 .ms-content-block {
  min-height: auto !important;
}

.imp_icons h4 {
  width: 235px !important;
  float: left !important;
  font-weight: normal !important;
  padding-left: 38px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: -9px -1161px;
  padding: 26px 0;
  background-repeat: no-repeat;
  background-size: 28%;
}

.imp_icons h5 {
  width: 300px !important;
  float: left !important;
  font-weight: normal !important;
  padding-left: 35px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 0px -735px;
  padding: 26px 0;
  background-repeat: no-repeat;
  background-size: 15%;
}

.GEWINNSPIELE .ms-content-block {
  min-height: auto !important;
}

.GEWINNSPIELE .msc-cta__primary {
  display: inline-block !important;
  margin: 10px auto !important;
  background-color: #fff !important;
  padding: 8px !important;
  font-family: trebuchet !important;
  font-weight: 700 !important;
  color: #000 !important;
  border: 3px solid #000 !important;
  text-transform: uppercase !important;
  font-size: 1.2em !important;
  text-decoration: underline !important;
}

.GEWINNSPIELE_list1 ul {
  padding: 0px !important;
}

.GEWINNSPIELE_list1 ul li {
  list-style: none !important;
  margin-bottom: 0px !important;
}

.ms-checkout-gift-card__input-text {
  border: 1px solid #90989f !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
}

.ms-checkout-gift-card__btn-apply {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  display: inline-block !important;
  text-align: center !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  transition: 0.2s ease !important;
  text-transform: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.wish_list1 {
  max-width: 1100px !important;
  width: 1100px !important;
  margin: auto !important;
}

.wish_list1 .ms-wishlist-items__product-title,
.wish_list1 .ms-wishlist-items__product-dimensions {
  font-family: "Inter", sans-serif !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.wish_list1 h2 {
  text-transform: uppercase !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
}

.wish_list1 ul li {
  text-align: center !important;
}

.wish_list1 ul li button {
  margin: auto !important;
  padding: 5px;
  height: auto;
  font-size: 12px;
  min-width: 100px !important;
}

.loyalty .ms-loyalty-signup .ms-loyalty-signup__btn {
  background-color: #bf0072 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}

.loyalty {
  width: 980px !important;
  max-width: 980px !important;
  margin: auto !important;
}

.loyalty h2 {
  font-weight: normal !important;
  text-transform: uppercase !important;
  font-family: "Inter", sans-serif !important;
}

.faq_1 .drawer__button {
  width: 100% !important;
  background: #f6f6f6 !important;
  display: table !important;
  position: relative !important;
  padding: 0 40px !important;
  margin-bottom: 2px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: #231f20 !important;
  font-size: 29px !important;
  text-align: center !important;
  text-decoration: none !important;
  height: 86px;
  cursor: pointer;
  transition: all linear 0.15s;
  border: none !important;
  outline: none !important;
  line-height: normal !important;
}

.loyalty_1 img,
.loyalty_2 img {
  width: auto !important;
  height: auto !important;
  margin: auto !important;
}

.loyalty_1 .ms-text-block {
  font-family: inter, sans-serif !important;
}

.loyalty_1 button,
.loyalty_2 button,
.loyalty_3 button {
  font-family: inter sans-serif !important;
  width: 100% !important;
  background: #f6f6f6 !important;
  display: table !important;
  position: relative !important;
  padding: 0 40px !important;
  margin-bottom: 2px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: #231f20 !important;
  font-size: 24px !important;
  text-align: center !important;
  text-decoration: none !important;
  height: 86px;
  cursor: pointer;
  transition: all linear 0.15s;
  border: none !important;
  outline: none !important;
}

.loyalty_1 p:nth-child(1),
.loyalty_2 p:nth-child(1) {
  font-size: 18px !important;
}

.loyalty_1 h2,
.loyalty_2 h2,
.loyalty_3 h2 {
  font-size: 18px !important;
  font-family: inter, sans-serif !important;
  font-weight: 900 !important;
  color: #000 !important;
  margin: 20px 20px 20px 0 !important;
  text-align: left !important;
}

.loyalty_3 h1 {
  font-size: 18px !important;
  font-family: inter, sans-serif !important;
  font-weight: 900 !important;
  color: #000 !important;
  margin: 20px 20px 20px 0 !important;
  text-align: left !important;
}

.ms-header__account-info-content {
  width: 320px !important;
  background-color: #fff !important;
  padding: 15px 15px 0px 15px !important;
}

.ms-header__account-info-content a {
  border: 1px solid #231f20 !important;
  background-color: #fff !important;
  color: #231f20 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 10px !important;
  text-transform: uppercase !important;
  display: block !important;
  text-align: center !important;
  height: auto !important;
  margin-bottom: 15px !important;
}

.ms-header__account-info-content a:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.addressbook_1 .msc-address-list__button-add {
  display: block !important;
  border: 1px solid #231f20 !important;
  background-color: #fff !important;
  color: #231f20 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 10px !important;
  text-transform: uppercase !important;
  display: block !important;
  text-align: center !important;
  border-radius: 0px !important;
  width: 275px !important;
}

.addressbook_1 .msc-address-list__button-add:hover {
  background-color: #231f20 !important;
  color: #fff !important;
}

.my_account3 .ms-address-list {
  margin-top: 60px !important;
}

.my_account3 .ms-address-list h2 {
  font-size: 16px !important;
}

.addressbook_1 .msc-address-list__primary {
  min-height: 135px !important;
  padding: 0px 0px 5px 0 !important;

  border: 1px solid #e5e5e5 !important;
  font-size: 12px !important;
  box-shadow: 0 4px 0 #f2f2f2 !important;
  position: relative !important;
  vertical-align: top !important;
  margin-bottom: 2em !important;
  margin-right: 10px !important;
}

.addressbook_1 .msc-address-list__primary:nth-child(odd) {
  width: 320px !important;
}

.addressbook_1 .msc-address-list__primary:nth-child(odd) h2 {
  background-color: #231f20 !important;
  color: #fff !important;
  padding: 9px 20px 9px 30px !important;
  font-size: 11px !important;
  font-weight: normal !important;
}

.addressbook_1 .msc-address-list__primary:nth-child(even) h2 {
  background-color: #ddd !important;
  color: #231f20 !important;
  padding: 9px 20px 9px 30px !important;
  font-size: 11px !important;
  font-weight: normal !important;
}

.addressbook_1 .msc-address-list__primary:nth-child(even) .msc-address-list__primary-list {
  display: inline-block !important;
  width: 315px !important;
  border-bottom: none !important;
}

.addressbook_1 .msc-address-list__primary .msc-address-list__primary-list {
  padding: 15px 10px 10px 30px !important;
}

.addressbook_1 .msc-address-list__button-edit {
  text-transform: uppercase !important;
  margin: 15px 0 0 80px !important;
  width: auto !important;
  font-size: 12px !important;
  text-decoration: none !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEimn);
  background-repeat: no-repeat;
  background-size: 25%;

  padding: 0px 0px 0px 18px !important;
  text-decoration: underline !important;
}

.addressbook_1 .msc-address-list__button-remove {
  text-transform: uppercase !important;
  margin: 15px 0 0 20px !important;
  width: auto !important;
  font-size: 12px !important;
  text-decoration: none !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/MEfK1);
  background-repeat: no-repeat;
  background-size: 25%;
  padding: 0px 0px 0px 18px !important;
  text-decoration: underline !important;
  background-position: 3px 4px !important;
}

.addressbook_1 .msc-address-list__button-primary {
  font-size: 12px !important;
  padding: 5px !important;
  line-height: normal !important;
  height: auto !important;
  float: left !important;
}

.addressbook_1 .msc-address-list__button-primary:hover {
  background-color: #fff !important;
}

.addressbook_1 .msc-address-detail {
  min-height: 103px !important;
}

.Loyalty_page {
  margin: auto !important;
  max-width: 980px !important;
  width: 980px !important;
}

.Loyalty_page .ms-account-loyalty__home-link {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.Loyalty_page .ms-account-loyalty__point-breakdown {
  border-bottom: solid 1px #ccc !important;
  padding-bottom: 10px !important;
}

.Loyalty_page .breadcrumb p,
.Loyalty_page .breadcrumb a {
  font-size: 11px !important;
  font-family: inherit !important;
  color: #8e8083 !important;
  padding-top: 10px;
}

.ms-wishlist-items__product-link {
  width: 258px !important;
}

.ms-wishlist-items__product .msc_image {
  width: 258px !important;
  height: 258px !important;
}

.ms-wishlist-items__product {
  margin: 0 5px 60px 0 !important;
}

.ms-cart-icon__flyout-container {
  width: 320px !important;
}

.ms-cart-icon__flyout-container .ms-cart-icon__flyout-title {
  display: block !important;
}

.check_out1 .ms-order-confirmation__btn-keep-shopping {
  color: #fff !important;
  background-color: #ec008d !important;
  border: solid 1px #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.check_out1 .ms-order-confirmation__group-delivery-total-items {
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
}

.social-items1 li:nth-child(3) img {
  width: 30px !important;
  height: 27px !important;
}

.msc-flyout-inner .ms-cart-icon__btn-gotocart {
  color: #fff !important;
  background-color: #ec008d !important;
  border: solid 1px #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.msc-order-summary__heading {
  font-family: "Inter", sans-serif !important;
}

.wishlist_img img {
  height: auto !important;
  width: 100% !important;
}

.guide_page {
  max-width: 1200px !important;
  width: 1200px !important;
}

.ms-nav__list__item div ul {
  padding: 0px !important;
  margin: 0px !important;
}

.carousel-area {
  max-width: 1170px !important;
}

.carousel-area-mobile {
  display: none !important;
}

.guide_top_images h1 {
  position: absolute !important;
  top: 45% !important;
  font-size: 28px !important;
  line-height: 30px !important;
  width: 100% !important;
  z-index: 2;
  letter-spacing: 1px !important;
  font-family: trebuchet ms;
  font-weight: bold;
  transition: 0.5s;
  color: #ffffff;
  width: 125px !important;
  margin: auto !important;
  left: 55px !important;
  text-align: center !important;
}

.guide_top_images h1 a {
  color: #fff !important;
}

.guide_top_images .ms-content-block__image {
  margin-right: 2px !important;
  overflow: hidden;
}

.guide_top_images img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.guide-page .ms-breadcrumb {
  width: 1090px !important;
}

.guide_top_images img:hover {
  transform: scale(1.1);
  /* filter: url(filters.svg#grayscale); Firefox 3.5+ */
  filter: gray;
  /* IE5+ */
  -webkit-filter: grayscale(1);
  /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.8s ease-in-out;
}

.guide-page1 {
  text-align: center !important;
  width: 1170px !important;
  max-width: 1170px !important;
  margin: auto !important;
}

.guide-page1 h1 {
  font-size: 80px !important;
  line-height: 90px !important;
  font-family: "CabernetJFPro" !important;
  font-weight: 900 !important;
}

.guide-page1 h3 {
  font-size: 24px !important;
  line-height: 34px !important;
  font-family: trebuchet ms !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  margin: 15px auto 30px !important;
}

.guide-page1 p {
  font-size: 20px !important;
  line-height: 30px !important;
  margin-bottom: 70px !important;
  font-family: trebuchet ms !important;
}

.order-history {
  max-width: 1090px !important;
  width: 1090px !important;
}

.order-history .ms-order-history__btn-order-details,
.order-history .ms-order-details__sales-line-btn-buy-it-again {
  color: #222222 !important;
  background-color: #ffffff !important;
  border: solid 1px #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.order-history .ms-order-history__btn-order-details:hover,
.order-history .ms-order-details__sales-line-btn-buy-it-again:hover {
  color: #222222 !important;
  background-color: #ffffff !important;
}

.order-history .ms-order-history__btn-more {
  color: #fff !important;
  background-color: #ec008d !important;
  border-color: #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.order-history .ms-order-history__order-information-channel-name {
  background-color: #fff !important;
  font-family: "Inter", sans-serif !important;
}

.order-history .ms-order-details__sales-status {
  color: #222 !important;
}

.order-history .ms-order-details__order-information-qr-code {
  display: block !important;
  margin: 20px 0 0 0 !important;
}

.order-history h2 {
  text-transform: uppercase !important;
  font-family: "Inter", sans-serif !important;
}

.order-history .ms-order-details__group-delivery {
  padding-bottom: 15px !important;
  border-bottom: solid 1px #ccc !important;
}

.order-history .ms-order-history__sales-order {
  width: 348px !important;
  display: inline-block !important;
  min-height: 375px !important;
  border: none !important;
}

.ms-header .ms-header__nav-icon {
  background-color: #fff !important;
}

.msc-address-form__button-save {
  color: #fff !important;
  background-color: #ec008d !important;
  border: solid 1px #ec008d !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.wish_list1 .ms-wishlist-items__product-add-button {
  display: none !important;
}

.ms-fullscreen-section__magnifying-glass-icon:after {
  content: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME1li8?pubver=1) !important;
}

.msc-add-to-wishlist:after {

  color: #000 !important;
}

/* qs  */

.guide-page .ms-video-player {
  width: 300px;
  vertical-align: baseline;
  display: inline-block;
}

.guide-page-inner {
  max-width: 1270px !important;
  margin-left: 140px !important;
}

.guide-page-inner .ms-text-block {
  text-align: left;
  margin: 60px 30px 0 0px;
  max-width: 540px !important;
}

.guide-page .ms-text-block h2 {
  font-size: 65px !important;
  line-height: 75px !important;
  margin: 0 auto 10px !important;
  font-family: "CabernetJFPro";
  font-weight: normal;
  color: #000000;
  text-transform: inherit;
}

.guide-page .ms-text-block h3 {
  font-size: 24px;
  line-height: 34px;
  margin: 15px auto 30px;
  font-family: trebuchet ms;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.guide-page .ms-text-block p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 70px;
  font-weight: normal;
}

.guide-page .ms-image-list {
  text-align: center;
  margin: 70px auto;
}

.guide-page .ms-content-block {
  text-align: center !important;
}

.guide-page-inner-full .ms-content-block__link .ms-content-block__image img {
  display: flex;
  margin: 40px auto 0 auto;
  transform: rotate(180deg);
  scroll-behavior: smooth;
  transition: 0.5s;
}

.guide-page-inner .ms-content-block .ms-content-block__image img {
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  max-height: 720px;
}

.guide-page .default-container {
  padding: 0px !important;
}

.guide-page .container {
  max-width: 1340px;
}

.guide-page-bottom-button a {
  width: max-content;
  display: inline-block;
  padding: 0 60px 0 60px;
  border: 0;
  color: #ffffff;
  font-size: 20px;
  line-height: 42px;
  background: #000000;
  margin: 40px auto 60px auto;
}

.guide-page-top-arrow {
  text-align: center !important;
}

.guide-page-top-arrow img:first-child {
  display: inherit !important;
}

.max-width-container {
  max-width: 1200px !important;
}

a.ms-order-confirmation__btn-keep-shopping.msc-btn {
  background-color: #ec008d !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border: none !important;
}

.disclaimer .ms-text-block p {
  text-align: center;
  font-size: 11px;
  color: #8e8083;
}

.footer_area2 .col-12:nth-child(1),
.footer_area2 .col-12:nth-child(4) {
  border-right: 1px solid #e5e5e5;
}

.home-showmore-btn .drawer__button {
  color: #8e8083;
  text-decoration: underline;
  cursor: pointer;
  font-size: 11px !important;
}

.home-showmore-btn .drawer__button:hover {
  color: #8e8083 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.fa-facebook::before {
  position: absolute !important;
  color: #ababab !important;
}

.fa-instagram::before {
  position: absolute !important;
  color: #ababab !important;
}

.footer_box3 .ms-content-block__details {
  margin-top: 10px !important;
}

.footer_box3 .msc-cta__secondary {
  margin-top: -15px !important;
}

.social-background {
  margin-right: 25px;
}

.social-items1 .ms-footer__link {
  width: 30px !important;
  height: 30px !important;
  z-index: 999;
  position: absolute;
  background-color: transparent !important;
}

.social-tiktok .ms-footer__link {
  margin-top: 20px;
}

.ms-buybox__quantity {
  display: none !important;
}

.msc-add-to-wishlist__removing:after,
.msc-add-to-wishlist:after {
  color: #000 !important;
}

.ms-buybox .product-add-to-cart .ms-wishlist-items__product-status {
  display: none !important;
}

.msc-add-to-cart::before {
  content: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME1lib?pubver=1) !important;
  top: 6px;
  position: relative;
}

/* myaccount */
.my_account2 .ms-tile-list .ms-tile-list__heading {
  padding: 0px 20px;
}

.my_account2 .ms-tile-list .ms-tile-list__title {
  border-bottom: 1px solid;
  margin-bottom: 30px;
  text-align: center;
  padding-bottom: 10px;
  height: 46px !important;
}

.my_account2 .ms-tile-list__title:before {
  content: "";
  display: block;
  border: 14px solid transparent;
  border-top-color: #231f20;
  position: absolute;
  z-index: 0;
  bottom: 69px;
  left: 50%;
  margin-left: -14px;
}

.my_account2 .ms-tile-list__title:after {
  content: "";
  display: block;
  border: 27px solid transparent;
  border-top-color: #fff;
  position: absolute;
  z-index: 1;
  bottom: 57px;
  left: 50%;
  margin-left: -27px;
}

/*myaccount*/
.ms-store-select__header .msc-modal__title,
.ms-store-select__modal-header .msc-modal__title {
  font-size: 38px !important;
  line-height: 38px !important;
  font-family: Inter, Tahoma !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

.ms-cookie-compliance__accept-button,
.ms-order-history__btn-keep-shopping,
.loyalty .ms-loyalty-signup__details {
  background-color: #ec008d !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border: none !important;
  color: #fff !important;
}

/* addressbook */

.addressbook_1 .msc-address-list__button-primary {
  width: 100% !important;
}

.addressbook_1 .msc-address-list__button-edit {
  display: inline-block !important;
  margin: 0px !important;
}

.addressbook_1 .msc-address-list__button-remove {
  display: inline-block !important;
}

/* addressbook */
/*order-history*/

.order-history .ms-order-history__header {
  margin-bottom: 20px !important;
}

.order-history .ms-order-history__sales-order {
  border: 1px solid #d1d1d1 !important;
  padding: 20px !important;
  margin-right: 20px !important;
}


/*order-history*/

.ms-cart-icon__flyout-container .ms-cart-icon__btn-checkout {
  display: none !important;
}

.profile_2 .ms-nav__list .ms-nav__list__item__span {
  background-color: transparent !important;
}


.footer1_temp_hide {
  display: none !important;
}

.footer1_main_text_block {
  margin-bottom: 150px;
}

.footer_1_left_link1 {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 5px -218px !important;
  background-repeat: no-repeat !important;
  background-size: 25% !important;
  height: 70px !important;
  width: 200px !important;
  display: block !important;
  padding-top: 70px !important;
  color: #8e8083 !important;
  text-decoration: none !important;
}

.footer_1_left_link2 {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 28px -275px !important;
  background-repeat: no-repeat !important;
  background-size: 25% !important;
  height: 70px !important;
  width: 200px !important;
  display: block !important;
  padding-top: 70px !important;
  color: #8e8083 !important;
  text-decoration: none !important;
  margin-left: 40px
}

.footer_1_left_link3 {
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME1vEw?f=jpg);
  margin-left: 110px;
  background-position: 18px 10px !important;
  background-repeat: no-repeat !important;
  background-size: 23% !important;
  height: 70px !important;
  width: 200px !important;
  display: block !important;
  padding-top: 70px !important;
  color: #8e8083 !important;
  text-decoration: none !important;
}

.footer_1_section_right {
  padding-left: 80px !important;
  border-left: 1px solid #eaeaea;
}

.footer_1_right_icon1 {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 74px -956px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 50%;
  padding: 5px 0 0px 38px;
  margin-top: 15px;
  width: 116px;
  height: 38px;
}

.footer_1_right_icon2 {
  font-size: 16px !important;
  color: #231f20 !important;
  font-weight: normal !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME2DS.png);
  background-position: 74px -1022px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 50%;
  padding: 5px 0 0px 38px;
  margin-top: 15px;
  width: 116px;
  height: 38px;
}

.footer_1_contact_number_p1 {
  display: inline-block;
  font-size: 18px;
  color: #231f20;
}

.footer_1_contact_number_p2 {
  padding-left: 125px;
  font-size: 11px;
  color: #8e8083;
  margin-top: -10px;
}

.footer_1_contact_number_p3 {
  padding-left: 125px;
  font-size: 11px;
  color: #8e8083;
  margin-top: 0px;
}


.footer_1_message_p1 {
  display: inline-block;
  font-size: 14px;
  color: #231f20;
}


.footer_1_message_p1 a {
  text-decoration: none;
}


.footer_1_message_p2 {
  padding-left: 125px;
  font-size: 11px;
  margin-top: -10px;
}

.footer_1_message_p2 a {
  text-decoration: none;
  color: #8e8083;
}

.checkout_header_main_container {
  padding-top: 30px;
}

.checkout_header_main_container div[class^="col"] {
  width: 33% !important;
}

.checkout_header_middle,
.checkout_header_left {
  width: 100%;
}

.checkout_header_left .msc-cta__primary {
  vertical-align: super;
  padding-left: 10px;
  font-size: 13px;

}

.checkout_header_left .ms-content-block__details {
  display: inline-block;
}

.checkout_header_left .ms-content-block__link {
  display: inline-block;
}

.checkout_header_left {
  padding-left: 115px;
}

.checkout_header_middle {
  text-align: center;
}

.checkout_header_right {
  width: 26%;
}

.checkout_header_right {
  float: right;
}

.footer_area_21 .footer_box6 .ms-content-block__text a {
  font-size: 14px !important;
}

.signin_main-container .container-heading {
  margin-left: 43% !important;
  font-weight: 400 !important;
  font-size: 36px !important;
  font-family: Inter, Tahoma !important;
}

.passwordresetverification_main-container .container-heading {
  margin-left: 38% !important;
  font-weight: 400 !important;
  font-size: 36px !important;
  font-family: Inter, Tahoma !important;
}

.signin_main-container .alignment__left {
  text-align: center !important;
}

.signin_main-container .alignment__left h3 {
  font-weight: 400 !important;
}

.ms-account-profile-edit .ms-account-profile-edit__save-button,
.ms-sign-up__container .ms-sign-up__email-verification-button,
.ms-sign-up__container .ms-sign-up__create-button,
.signin_main-container .ms-sign-in__sign-up-link,
.signin_main-container .ms-sign-in__sign-in-button {
  background-color: #ec008d;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  color: #fff;
  padding: 0.8em 0;
  display: block;
  border-radius: 4px;
  transition: .15s ease;
  text-transform: none;
  text-align: center;
}


.ms-account-profile-edit .ms-account-profile-edit__cancel-button,
.signin_main-container .ms-sign-in__social-account {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #6f6f6f !important;
}


.passwordreset_main-container .ms-password-reset__continue-button,
.passwordreset_main-container .ms-password-reset__cancel-button,
.passwordresetverification_main-container .ms-password-reset-verification__cancel-button,
.passwordresetverification_main-container .ms-password-reset-verification__email-verification-button,
.passwordresetverification_main-container .ms-password-reset-verification__continue-button {
  background-color: #fff !important;
  border: 1px solid #231f20 !important;
  color: #231f20 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 10px !important;
  text-transform: uppercase !important;
  display: block !important;
  text-align: center !important;
  transition: background-color, color .2s ease-in-out 0s !important;
  width: 255px !important;
  margin-left: 158px !important;
}

.passwordresetverification_main-container .ms-password-reset-verification__account-item-email {
  width: 355px !important;
}

.passwordresetverification_main-container .ms-password-reset-verification__account-item {
  margin-left: 114px !important;
}

.passwordreset_bottom-image {
  display: grid !important;
  justify-content: center !important;
}

.passwordreset_bottom-image .ms-content-block__image {
  max-width: 980px !important;
  max-height: 720px !important;
  margin-top: 80px !important;
  margin-bottom: 80px !important;

}

.passwordreset_main-container .ms-password-reset__account-item {
  padding: 10px;
  border: 1px solid gray;
  margin-bottom: 20px !important;
  width: 350px;
}

.passwordreset_main-container .ms-password-reset__account-item-input {
  border: 0px;
  background: transparent !important;
}

.passwordreset_main-container,
.passwordresetverification_breadcrumb-container,
.passwordresetverification_main-container,
.signin_breadcrumb-container,
.signin_main-container,
.signup_main-container {
  background-color: #f4efeb !important;
  max-width: 1600px !important;
}

.signup_main-container .ms-sign-up__heading {
  text-align: center !important;
}

.ms-sign-up__container .ms-sign-up__sign-up-disclaimer {
  color: #6f6f6f !important;
  font-size: 11px !important;
  text-transform: none !important;
}

.signup_last-textblock {
  margin-top: -38px !important;
  ;
  margin-left: 30% !important;
  ;
  color: #6f6f6f !important;
  font-size: 11px !important;
  text-transform: none !important;
}



/*profile edit de*/

.ms-account-profile-edit .ms-account-profile-edit__account-item {
  padding: 10px !important;
  border: 1px solid #aaa !important;
  border-radius: 5px !important;
}

.ms-account-profile-edit .ms-account-profile-edit__account-item-label {
  color: #aaa;
  font-size: 14px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.ms-account-profile .ms-account-profile__section-link {
  text-decoration: underline !important;
}

/*profile edit de*/

.cart_1 .msc-cart-line__add-to-wishlist {
  display: none;
}


/*at*/

.at-main-footer .footer_area1 .col-12 {
  max-width: 590px !important;
}

.at-main-footer .footer_box4 h2 {
  background-position: 110px -818px !important;
  background-size: 10% !important;
}

.msc-cart__btn-backtoshopping:before {
  content: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/jvrcdvtnhn/imageFileData/ME1yfU?pubver=1);
  margin-right: 10px;
}

.ms-sign-in__social-accounts {
  display: none !important;
}

/* qs  */

@media screen and (max-width: 768px) {
  .footer_box2 .msc-cta__secondary {
    background-position: 200px -682px !important;
  }

  .footer_area1 .ms-content-block {
    min-height: 100px !important;
  }

  footer {
    padding-top: 50px !important;
  }

  .footer_area_nav .ms-nav__list__item__link {
    padding: 0 10px !important;
  }

  .products_home1 .ms-product-collection__item {
    padding: 5px 5px 20px 0px !important;
    width: 228px !important;
  }

  .ms-header__account-info {
    margin: 0px !important;
  }

  .ms-header__container .ms-header__collapsible-hamburger .ms-nav.mobile-vp {
    padding: 0px !important;
  }

  .ms-header__wishlist-mobile {
    display: none !important;
  }

  .ms-search__form-submitSearch {
    background-color: #ec008d !important;
  }

  .ms-header__account-info button {
    border: none !important;
  }

  .ms-header__mobile-hamburger-menu-links .ms-site-picker {
    display: none !important;
  }

  .disclaimer p {
    padding: 0 10px !important;
  }

  .footer_area2 .ms-footer__list {
    padding-top: 15px !important;
  }

  .footer_box4 {
    padding-bottom: 15px !important;
    padding-left: 5px !important;
  }

  .footer_area2 .row {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  body .container {
    padding: 0px !important;
    margin: 0px !important;
  }

  .main_carousel1 .msc-cta__primary {
    position: absolute !important;
    bottom: 40px !important;
    left: 90px !important;
  }

  .main_carousel1 .msc-cta__primary div {
    margin: auto !important;
  }

  .hero.ms-content-block .ms-content-block__details {
    background: transparent !important;
    height: 0px !important;
    padding: 0px !important;
    max-width: 0px !important;
  }

  .content_tile1 .col-12 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .content_tile1 .msc-cta__primary {
    left: 95px !important;
    bottom: 35px !important;
  }

  .ms-content-block[data-m-layout="tile"] {
    padding: 0px !important;
  }

  .ms-content-block[data-m-layout="tile"] .ms-content-block__details {
    padding: 0px !important;
  }

  .products_home1 .msc-ss-carousel__flipper--next {
    left: 310px !important;
  }

  .products_home1 .msc-product .msc_image {
    width: 215px !important;
    height: 215px !important;
  }

  .ms-header {
    width: auto !important;
  }

  .ms-promo-banner__links .ms-promo-banner__link:nth-child(1),
  .ms-promo-banner__links .ms-promo-banner__link:nth-child(2) {
    display: none !important;
  }

  .ms-promo-banner__links .ms-promo-banner__link:nth-child(3) {
    padding-left: 0px !important;
  }

  .ms-promo-banner__link {
    font-size: 12px !important;
  }

  .ms-header .ms-header__wishlist-desktop {
    width: 32px !important;
    display: block !important;
    padding-top: 14px !important;
    padding-left: 0px !important;
  }

  .carousel-area {
    display: none !important;
  }

  .carousel-area-mobile {
    display: block !important;
  }

  .carousel-area-mobile img {
    height: 100% !important;
  }

  .img_list1 ul {
    white-space: nowrap !important;
    overflow-y: hidden !important;
  }

  .ms-header .ms-header__nav-icon {
    background-color: #fff !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footer_area_nav .ms-nav__list__item__link {
    padding: 0 10px !important;
  }

  .img_list1 ul {
    white-space: nowrap !important;
    overflow-y: hidden !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .ms-header {
    width: 100% !important;
  }

  .products_home1 .msc-ss-carousel__flipper--next {
    left: 930px !important;
  }

  .footer_area1 .col-12 {
    max-width: 340px !important;
  }

  .footer_box5 h2 {
    width: auto !important;
    font-size: 14px !important;
  }

  .footer_box5 p {
    width: auto !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {}

@media only screen and (min-width: 1024px) {


  .cart_1 .msc-cart-line__remove-item {
    left: 480px !important;
  }


  /* q ^*/


  .ms-search__searchForm {
    background-color: #eee !important;
    border-radius: 50px;
    height: 35px !important;
  }

  .ms-search__form-control {
    background: transparent !important;
    padding: 0 15px !important;
    display: block !important;
    border: none !important;
    max-width: 100% !important;
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
    height: auto !important;
  }

  .ms-search__form-cancelSearch,
  .ms-search__label {
    display: none !important;
  }

  .ms-search__form-submitSearch {
    background: transparent !important;
  }

  .ms-search__form-submitSearch::before {
    color: #000 !important;
    top: -5px;
    position: relative;
  }

  .ms-search .bx-hide {
    display: block !important;
    width: 575px;
    margin: auto;
  }
}

.script-form {
  position: fixed;
  background: white;
  top: 50%;
  z-index: 100000000;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  overflow: auto;
  width: 100%;
  max-width: 600px;
  max-height: calc(100vh- 100px);
  padding: 24px;

  .close-button {
    font-weight: bold;
    position: absolute;
    right:16px;
    top:16px;
    font-size: 18px;
  }
}

.overlay-script-form {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 10000;
}

/* UAT CSS OVERRIDE */

/* GRAFIX */
.promo-banner-top {
  padding: 10px !important;
  background-color: #ff66c4 !important;
  border: none;
}

@media screen and (max-width: 768px) {
  .ms-promo-banner__links .ms-promo-banner__link:nth-child(1) {
    display: block !important;
  }
}

.banner-top-c .msc-cta__primary {
  left: 348px !important;
}

.carousel-area-test {
  max-width: 100% !important;
  padding: 0px !important;
}

.custom-container {
  padding-left: 25px !important;
  padding-right: 25px !important;
  max-width: 1170px !important;
}

.custom-section-device {
  display: none;
}


/* q_april */

@media (max-width: 992px) {

  .ms-buybox .ms-media-gallery .msc-carousel__control__prev,
  .ms-buybox .ms-media-gallery .msc-carousel__control__next {
    bottom: 196px !important;
  }

  .ms-buybox .ms-media-gallery .msc-carousel__indicators {
    bottom: 200px !important;
  }

  .cart_1 .msc-cart-line__remove-item {
    right: -210px !important;
  }

  .cart_1 .msc-cart-line__product-savings {
    top: 210px !important;
  }

  .page_container_pdp_pmk1 .msc-add-to-wishlist {
    left: 0px !important;
  }

  .footer_box2_cart .ms-content-block__details {
    padding-left: 0px;
  }

  .footer_box2_cart {
    margin-left: 50px;
  }

  .footer_area_cart .footer_box1_cart a {
    background-position: 0px -205px !important;
    background-size: 30% !important;
    width: 160px !important;
  }

}

@media (max-width: 360px) {
  .cart_1 .msc-order-summary-wrapper {
    max-width: 360px !important;
  }
}

/* q_april */


/* GRAFIX */
.shops-table-c-w {
  overflow-x: auto;
}
#shops-table-c {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
#shops-table-c td, #shops-table-c th {
  border: 1px solid #ff66c4;
  padding: 8px;
}
#shops-table-c td {
  border: 1px solid #ddd;
  padding: 8px;
}
#shops-table-c tr:nth-child(even){background-color: #f2f2f2;}
#shops-table-c tr:hover {background-color: #ddd;}
#shops-table-c th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ff66c4;
  color: white;
}
.title-full-c h1 {
  font-size: 38px !important;
  text-transform: uppercase !important;
  color: #231f20 !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin: 30px !important;
}
@media (max-width: 768px){
  .shops-table-c-w {
    padding: 0 15px;
  }
}